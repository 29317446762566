import React, { Component } from "react";
import { NavItem } from "react-materialize";

class AgentMessageTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <NavItem
        className="nav-item-history"
        value={this.props.listItem.message}
        onClick={(event) => this.selectTemplate(event)}
      >
        {this.props.listItem.message}
      </NavItem>
    );
  }
  selectTemplate(event) {}
}

export default AgentMessageTemplate;
