import React, { Component } from "react";
import { AuthProvider } from "oidc-react";

class WarningVersion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      iOS: `https://itunes.apple.com/th/app/ttrs-live-chat/id1199805750?l=th&mt=8`,
      Android: `https://play.google.com/store/apps/details?id=th.or.ttrs.livechat&hl=th`,
      oidcConfig: {
        onSignIn: async () => {
          // const url = document.location.href;
          window.location.hash = "";
          // window.history.pushState({}, "", url.split("?")[0]);
          // updateAccessToken();
        },
        authority: process.env.REACT_APP_ISSUER_AGENT,
        clientId: process.env.REACT_APP_CLIENT_ID_AGENT,
        redirectUri: `${process.env.REACT_APP_DOMAIN}${window.location.pathname}`,
      },
    };
  }
  render() {
    return (
      <AuthProvider {...this.state.oidcConfig}>
        <div>
          <div align="center">
            <br />
            <br />
            <h5>กรุณาอัพเดทเวอร์ชั่น</h5>
          </div>
          <div align="center">
            <h5>TTRS Live Chat</h5>
          </div>
          <div align="center">
            <h4>
              <a href={this.state.iOS}>iOS</a>
            </h4>
          </div>
          <div align="center">
            <h4>
              <a href={this.state.Android}>Android</a>
            </h4>
          </div>
        </div>
      </AuthProvider>
    );
  }
}
export default WarningVersion;
