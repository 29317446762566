import React, { Component } from "react";
import ChatContent from "./components/chat_content";
import ChatInput from "./components/chat_input";
import NavLivechat from "./components/nav_livechat";

class ClientApp extends Component {
  constructor(props) {
    super(props);
    this.messageSend = this.messageSend.bind(this);
    this.state = {
      urlWS: process.env.REACT_APP_URL_WS,
      urlAPI: process.env.REACT_APP_URL_API,
      channel: null,
      threadid: null,
      socket: null,
      threadstatus: null,
      threadtype: null,
      welcomemsg: false,
      user: "client",
      reconnected: "",
      data: [],
      datarealtime: [],
      autoScroll: true,
      messageComing: false,
    };
  }

  componentDidMount() {
    this.setState({
      channel: this.addChannel(),
    });
    document.title = "TTRS Live Chat";
  }
  keepOnPage(e) {
    var message = "เมื่อทำการรีเฟรชหน้าต่าง ห้องสนทนาจะถูกปิด";
    e.returnValue = message;
    return message;
  }
  // handleScroll(ev) {
  //     let element = ev.target
  //     if ((element.scrollHeight - element.scrollTop - 200) <= element.clientHeight) {
  //         if(!this.state.autoScroll){
  //             this.setState({autoScroll : true, messageComing : false});
  //         }
  //     }else{
  //         if(this.state.autoScroll){
  //             this.setState({autoScroll : false});
  //         }
  //     }
  // }
  connectSocket(channel, type) {
    const socket = new WebSocket(`${this.state.urlWS}/ws/${channel}`);
    socket.onopen = (evt) => {
      if (this.state.welcomemsg === false) {
        var dataconnect = {
          sender: "automessage",
          realtime: false,
          text: "clientconnect",
        };
        this.setState({
          welcomemsg: true,
        });
        socket.send(JSON.stringify(dataconnect));
      } else {
        this.state({
          reconnected: "",
        });
      }
    };
    socket.onmessage = (event) => {
      var parsedata = JSON.parse(event.data);
      if (typeof parsedata === "object") {
        this.messageChat(parsedata);
      }
    };
    socket.onclose = () => {
      this.setState({
        reconnected: "Reconnecting",
      });
      setTimeout(() => {
        this.connectSocket(channel, "reconnect");
      }, 1000);
    };
    socket.timeout = 1000;
    this.setState({ socket });
  }

  addChannel() {
    var personaldata = [];
    console.log("this.props.emergency_type", this.props.emergency_type);
    if (this.props.type === "reactInternal") {
      personaldata = {
        sender: "client",
        identification: this.props.identification,
        name: this.props.name,
        lastname: this.props.lastname,
        mobile: this.props.mobile,
        mobilecontact: this.props.mobileContact,
        email: this.props.email,
        priority: this.props.priority,
        emergency_type: this.props.emergency_type,
        emergency_type1_options: this.props.emergency_type1_options,
        question0: this.props.question1,
        // question1sub1  : this.props.question1sub1,
        question1: this.props.question2,
        question2: this.props.question3,
        userAgent: navigator.userAgent,
        platform: navigator.platform,
      };
    } else {
      var name = prompt("กรอกชื่อผู้ใช้งาน");
      personaldata = {
        identification: "99999999999999",
        name: name,
        lastname: "",
        mobile: "092-1234567",
        sender: "agent",
        email: "dweton@gmail.com",
        priority: 1,
        userAgent: navigator.userAgent,
        platform: navigator.platform,
      };
    }
    fetch(`${this.state.urlAPI}/api/channel/add`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(personaldata),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        this.connectSocket(data.channel, "initial");
        this.setState({
          channel: data.channel,
          threadid: data.threadid,
          threadstatus: "chat",
        });
        return data.channel;
      });
  }

  messageSend(data) {
    if (this.state.socket !== null) {
      this.state.socket.send(JSON.stringify(data));
    }
  }
  messageChat(data) {
    if (data.sender === "automessage") {
      if (data.text === "close;") {
        var type = null;
        try {
          type = data.type;
        } catch (error) {}
        this.setState({
          threadstatus: "close",
          threadtype: type,
        });
      }
      if (data.text === "clientconnect") {
      }
    } else if (data.realtime === false) {
      this.setState({
        data: [...this.state.data, data],
      });
      if (data.sender !== this.state.user) {
        this.setState({
          datarealtime: [],
        });
      }
    } else {
      if (data.sender !== this.state.user) {
        this.setState({
          datarealtime: data,
        });
      }
    }
  }

  render() {
    return (
      <div>
        <NavLivechat state={this.state} />
        <div
          className={
            this.state.threadstatus === "close" ? "close-thread-chatboxcontent" : "chatboxcontent"
          }
        >
          <ChatContent
            user={this.state.user}
            messageChat={this.state.data}
            messageChatRealtime={this.state.datarealtime}
            threadStatus={this.state.threadstatus}
            autoScroll={this.state.autoScroll}
          />
        </div>
        <ChatInput
          user={this.state.user}
          urlAPI={this.state.urlAPI}
          messageSend={this.messageSend}
          threadStatus={this.state.threadstatus}
          buttonInputDisabled={this.state.threadstatus === "close" ? true : false}
        />
      </div>
    );
  }
}
export default ClientApp;
