import React from "react";

const EmergencyNiems = (props) => {
  const handleEmergency = () => {
    fetch(`${process.env.REACT_APP_URL_API_NIEMS}/auth/sign`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        identification_inform: props.data.state.identification,
        latitude: props.data.state.latitude === "undefined" ? props.data.state.latitude : "0",
        longitude: props.data.state.longitude === "undefined" ? props.data.state.longitude : "0",
        ttrs_info: `https://livechat.ttrs.or.th/guest/${props.data.state.threadid}`,
        question1: props.data.state.question1,
        question1sub1: props.data.state.question1sub1,
        question2: props.data.state.question2,
        question3: props.data.state.question3,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        window.open(data.url);
      });
  };

  return (
    <div>
      <a href={() => false} className="change-agent-btn" onClick={handleEmergency}>
        แจ้งการแพทย์ฉุกเฉิน
      </a>
    </div>
  );
};

export default EmergencyNiems;
