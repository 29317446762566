import React, { Component } from "react";
import ChatMessageItem from "./chat_message_item";
import ChatMessageRealtime from "./chat_message_realtime";

class ChatContent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    if (!this.props.messageChat) {
      return <div></div>;
    }
    const chatItem = this.props.messageChat.map((messageChat, index) => {
      return (
        <ChatMessageItem
          key={index}
          id={index}
          user={this.props.user}
          messageChat={messageChat}
          autoScroll={this.props.autoScroll}
        />
      );
    });
    return (
      <div
        className={this.props.user === "history" ? "chatdata-history" : "chatdata"}
        id="chatdata"
      >
        {chatItem}
        <ChatMessageRealtime
          user={this.props.user}
          messageChatRealtime={this.props.messageChatRealtime}
          autoScroll={this.props.autoScroll}
        />
        <div className="empty"></div>
      </div>
    );
  }
}

export default ChatContent;
