import React, { Component } from "react";
import { Dropdown } from "react-materialize";
import AgentMessageTempate from "./agent_msg_template";
import styled from "styled-components";
import Picker from "emoji-picker-react";
import * as $ from "jquery";

const dateTime = require("node-datetime");
var StyledDivChat = styled.div`
  width: calc(100% - 240px);
  margin-top: 2px;
  margin-left: 5px;
`;

class ChatInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emojiSelect: "none",
      name: "",
      sender: this.props.user,
      inputCss: "livechat-input",
      realtime: false,
      text: "",
      emojiBtn: "😊",
      dtmcreated: dateTime.create().format("Y-m-d H:M:S"),
    };
  }
  componentDidMount() {
    if (this.props.user === `agent`) {
      StyledDivChat = styled.div`
        width: calc(100% - 330px);
        margin-top: 3px;
        margin-left: 5px;
        margin-right: 5px;
        float: left;
      `;
    }
    if (this.props.name) {
      this.setState({
        name: this.props.name,
      });
    }
    this.elem.focus();
    document.addEventListener("mousedown", this.handleClick, false);
  }
  UNSAFE_componentWillMount() {
    document.removeEventListener("mousedown", this.handleClick, false);
  }
  handleClick = (e) => {
    if (!this.emojiSelect.contains(e.target)) {
      this.setState({
        emojiSelect: "none",
      });
    }
  };
  render() {
    var agentMessageTempate = "",
      dropdownTemplate = "";
    if (this.props.user === "agent") {
      if (this.props.agentMessageTemplate) {
        agentMessageTempate = this.props.agentMessageTemplate.map((listItem, index) => {
          if (listItem) {
            return <AgentMessageTempate key={index} listItem={listItem} />;
          } else {
            return <div></div>;
          }
        });
      }
      dropdownTemplate = (
        <div className="message-template">
          <Dropdown
            onClick={(event) => this.selectAgentTemplate(event)}
            trigger={
              <input
                style={{ marginTop: "0px", boxShadow: "none" }}
                type="button"
                className="btn btn-primary"
                value="ข้อความ"
                disabled={this.props.threadStatus === "close" ? "disabled" : ""}
              />
            }
          >
            {agentMessageTempate}
          </Dropdown>
        </div>
      );
    }

    return (
      <div
        className={
          this.props.threadStatus === "close" ? "close-thread-chatboxfooter" : "chatboxfooter"
        }
      >
        {dropdownTemplate}
        <div ref={(emojiSelect) => (this.emojiSelect = emojiSelect)}>
          <Picker
            disableSearchBar={true}
            native={true}
            skinTone={false}
            onEmojiClick={this.addEmoji.bind(this)}
            preload={true}
            pickerStyle={{
              marginTop: "-321px",
              position: "absolute",
              right: 0,
              display: this.state.emojiSelect,
              boxShadow: "none",
            }}
          />
          {/* <Picker 
                        native={true}
                        showPreview={false}
                        include={["people"]}
                        onSelect={event => this.addEmoji(event)}
                        style={{ position: 'absolute', bottom: '56px', right: '0px', display:this.state.emojiSelect }} 
                    /> */}
        </div>
        <StyledDivChat className={this.state.inputCss}>
          <input
            type="text"
            style={{
              borderBottom: 0,
              marginTop: "2px",
              backgroundColor: "white",
              paddingLeft: "5px",
              paddingRight: "5px",
              border: "1px solid rgb(222, 222, 222)",
              borderRadius: "10px",
            }}
            className={
              this.props.threadStatus === "close"
                ? "close-input-field"
                : "input-field input-field-livechat"
            }
            disabled={this.props.threadStatus === "close" ? "disabled" : ""}
            autoFocus
            placeholder={this.props.threadStatus === "close" ? "" : "พิมพ์ข้อความตรงนี้"}
            ref={(elem) => {
              this.elem = elem;
            }}
            onChange={(event) => this.onChangeInput(event)}
            onKeyUp={(event) => this.onInputKey(event)}
            value={this.state.text}
          />
        </StyledDivChat>

        <div className="livechat-imgupload-div">
          <div
            className="file-field input-field"
            style={{
              marginTop: "5px",
              cursor: "pointer",
            }}
          >
            <div>
              <a href={() => false}>
                <label
                  className="uploadImgIcon"
                  htmlFor="uploadimage"
                  style={{
                    cursor: "pointer",
                    marginTop: "-10px",
                  }}
                >
                  <img
                    className="uploadImgIcon"
                    width="40px"
                    alt="addimage"
                    src={require(`../images/cameraicon.png`)}
                  />
                </label>
                <input
                  id="uploadimage"
                  type="file"
                  name="livechat"
                  accept="image/*"
                  style={{ backgroundColor: "black", cursor: "pointer", display: "none" }}
                  onChange={(event) => this.handleUpload(event)}
                />
              </a>
            </div>
          </div>
        </div>
        <div className="livechat-emoji-div">
          <span
            style={{
              backgroundColor: "transparent",
              fontSize: "30px",
              boxShadow: "none",
              marginLeft: "25%",
              cursor: "pointer",
            }}
            className="emoji-picker"
            disabled={this.props.buttonInputDisabled}
            onClick={(event) => this.onClickShowEmoji(event)}
          >
            {this.state.emojiBtn}
          </span>
        </div>
        <div className="livechat-send-div">
          <input
            type="button"
            style={{
              boxShadow: "none",
            }}
            className={this.props.user === "client" ? "btn btn-primary-client" : "btn btn-primary"}
            value="ส่ง"
            disabled={this.props.buttonInputDisabled}
            onClick={(event) => this.onClickSend(event)}
          />
        </div>
      </div>
    );
  }
  handleUpload(event) {
    if (this.isImage(event.target.files[0].name)) {
      var data = new FormData();
      data.append("livechat", event.target.files[0]);
      $.ajax({
        type: "POST",
        url: `${this.props.urlAPI}/api/upload/image`,
        data: data,
        dataType: "JSON",
        processData: false,
        contentType: false,
        success: function (data) {
          this.messageSend(false, `<image>${data.url}</image>`);
        }.bind(this),
      });
    }
  }
  isImage(filename) {
    var ext = this.getExtension(filename);
    switch (ext.toLowerCase()) {
      case "jpg":
        return true;
      case "gif":
        return true;
      case "bmp":
        return true;
      case "png":
        return true;
      default:
        return false;
    }
  }
  getExtension(filename) {
    var parts = filename.split(".");
    return parts[parts.length - 1];
  }

  onClickShowEmoji(event) {
    if (this.state.emojiSelect === "") {
      this.setState({
        emojiSelect: "none",
      });
    } else {
      this.setState({
        emojiSelect: "",
      });
    }
  }
  addEmoji(event, emojiObject) {
    this.setState({
      text: this.state.text + "" + emojiObject.emoji,
    });
    this.messageSend(true, this.state.text);
  }
  selectAgentTemplate(event) {
    this.setState({
      text: event.target.text,
    });
    this.elem.focus();
  }
  messageSend(realtime, text) {
    var data = {
      sender: this.props.user,
      dtmcreated: dateTime.create().format("Y-m-d H:M:S"),
      realtime,
      name: this.state.name,
      text,
    };
    this.props.messageSend(data);
  }
  onChangeInput(event) {
    this.setState({
      text: event.target.value,
    });
    this.messageSend(true, event.target.value);
  }
  onInputKey(event) {
    if (event.key === "Enter") {
      if (event.target.value !== "") {
        this.messageSend(false, event.target.value);
        event.target.value = "";
        this.setState({
          text: "",
        });
      }
    }
  }
  onClickSend(event) {
    this.setState({
      dtmcreated: dateTime.create().format("Y-m-d H:M:S"),
      realtime: false,
    });
    if (this.state.text !== "") {
      this.props.messageSend(this.state);
      this.setState({
        text: "",
      });
    }
  }
}
export default ChatInput;
