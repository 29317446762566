import React, { Component } from "react";
import { Row, Col, Button } from "react-materialize";
import { RadioGroup, ReversedRadioButton } from "react-radio-buttons";
import ClientApp from "../client";
import ClientAppMobile from "../client_mobile";
import styled from "styled-components";
import ReactDOM from "react-dom";
const StyledRow = styled(Row)`
  margin-bottom: 0;
  line-height: 0;
`;
var loginHeader = "";
class LoginDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileContact: "",
      priority: "0",
      priorityCheckNormal: true,
      priorityCheckEmergency: false,
      detailStlye: "rgb(125, 124, 124)",
      identification: "",
      name: "",
      lastname: "",
      email: "",
      mobile: "",
      prefixname: "",
      log: "",
      emailTextBtn: "อัพเดท",
      question1: 0,
      question1sub1: 0,
      question2: 0,
      question3: 0,
      questionClass: 3,
      platform: "",
      loginStatus: false,
      timeout: true,
    };
  }
  componentDidMount() {
    this.getUserDetail();
    this.elem.focus();
    document.title = "TTRS Live Chat";
    if (this.props.priority) {
      this.setState({
        priority: this.props.priority,
      });
    }
    this.setQuestionClass();
    this.setPlatform();
  }
  setPlatform() {
    if (
      navigator.userAgent.toLowerCase().indexOf("android") > -1 ||
      navigator.userAgent.toLowerCase().indexOf("iphone") > -1
    ) {
      this.setState({
        platform: "webmobile",
        detailStlye: "black",
      });
      loginHeader = <div className="m-loginDetailHeader"></div>;
    } else {
      loginHeader = (
        <Row align="center">
          <h4>ข้อมูลสมาชิก TTRS</h4>
        </Row>
      );
    }
  }
  setQuestionClass() {
    if (window.innerWidth <= 320) {
      this.setState({
        questionClass: 5,
      });
    } else if (window.innerWidth > 320 && window.innerWidth <= 550) {
      this.setState({
        questionClass: 4,
      });
    } else if (window.innerWidth > 550 && window.innerWidth < 850) {
      this.setState({
        questionClass: 3,
      });
    } else if (window.innerWidth >= 850) {
      this.setState({
        questionClass: 2,
      });
    }
  }
  render() {
    return (
      <div>
        {loginHeader}
        <StyledRow>
          <Col s={1}></Col>
          <Col s={10}>
            <label>หมายเลขบัตรประจำตัวประชาชน</label>
            <input
              type="text"
              className="login-detail validate "
              style={{ color: this.state.detailStlye }}
              value={this.state.identification}
              disabled="true"
            />
          </Col>
        </StyledRow>
        <StyledRow>
          <Col s={1}></Col>
          <Col s={5}>
            <label>ชื่อ</label>
            <input
              type="text"
              className="validate login-detail"
              style={{ color: this.state.detailStlye }}
              value={this.state.name}
              disabled="true"
            />
          </Col>
          <Col s={5}>
            <label>นามสกุล</label>
            <input
              type="text"
              className="validate login-detail"
              style={{ color: this.state.detailStlye }}
              value={this.state.lastname}
              disabled="true"
            />
          </Col>
        </StyledRow>
        <StyledRow>
          <Col s={1}></Col>
          <Col s={10}>
            <label>เบอร์มือถือ</label>
            <input
              type="text"
              className="validate login-detail"
              style={{ color: this.state.detailStlye }}
              value={this.state.mobile}
              disabled="true"
            />
          </Col>
        </StyledRow>
        <StyledRow>
          <Col s={1}></Col>
          <Col s={7}>
            <label>อีเมล์</label>
            <input
              type="text"
              className="validate login-detail"
              value={this.state.email}
              onChange={(event) => this.onEmailEdit(event)}
            />
          </Col>
          <Col s={3}>
            <Button
              waves="light"
              large
              node="a"
              style={{ marginTop: "14px" }}
              className="blue col s12"
              onClick={(event) => this.editEmail(event)}
              disabled={this.state.emailValidate}
            >
              {this.state.emailTextBtn}
            </Button>
          </Col>
        </StyledRow>
        <StyledRow>
          <Col s={1}></Col>
          <Col s={10}>
            <label>เบอร์ติดต่อ</label>
            <input
              type="text"
              className="validate login-detail"
              value={this.state.mobileContact}
              ref={(elem) => {
                this.elem = elem;
              }}
              onChange={(event) => this.onMobileContact(event)}
            />
          </Col>
        </StyledRow>
        <Row className="hide">
          <Col s={1}></Col>
          <Col s={10}>
            <RadioGroup onChange={this.onPriorityChange.bind(this)} horizontal>
              <ReversedRadioButton
                className="col s5"
                value="0"
                pointColor="#2196F3"
                rootColor="#828282"
                checked={this.state.priority === "0" ? true : false}
              >
                ปกติ
              </ReversedRadioButton>
              <ReversedRadioButton
                className="col s5"
                value="1"
                pointColor="red"
                rootColor="#828282"
                checked={this.state.priority === "1" ? true : false}
              >
                ฉุกเฉิน
              </ReversedRadioButton>
            </RadioGroup>
          </Col>
        </Row>
        <div className={this.state.priority === "0" ? "hide" : "show"}>
          <Row>
            <Col s={1}></Col>
            <div className="switch" onChange={(event) => this.onQuestionChange(event, 0)}>
              <Col s={this.state.questionClass}>
                <label>
                  ไม่ใช่
                  <input type="checkbox" />
                  <span className="lever"></span>
                  ใช่
                </label>
              </Col>
            </div>
            <label>รู้สึกตัวใช่ไหม</label>
          </Row>
          <Row>
            <Col s={1}></Col>
            <div className="switch" onChange={(event) => this.onQuestionChange(event, 1)}>
              <Col s={this.state.questionClass}>
                <label>
                  ไม่ใช่
                  <input type="checkbox" />
                  <span className="lever"></span>
                  ใช่
                </label>
              </Col>
            </div>
            <label>หายใจเหนื่อยใช่ไหม</label>
          </Row>
          <Row>
            <Col s={1}></Col>
            <div className="switch" onChange={(event) => this.onQuestionChange(event, 2)}>
              <Col s={this.state.questionClass}>
                <label>
                  ไม่ใช่
                  <input type="checkbox" />
                  <span className="lever"></span>
                  ใช่
                </label>
              </Col>
            </div>
            <label>ลุกแล้วยืนเวียนหัวใช่ไหม</label>
          </Row>
        </div>
        <StyledRow align="center">
          <Col s={1} />
          <Col s={10}>
            <Button
              waves="light"
              large
              node="a"
              className={this.state.loginStatus === false ? "disabled col s12" : "green col s12"}
              onClick={(event) => this.accessLiveChat(event)}
            >
              {" "}
              เข้าใช้งาน
            </Button>
          </Col>
          <Col s={1} />
        </StyledRow>
        <Row></Row>
        <Row>
          <center>
            <label className="userdetail-log">{this.state.log}</label>
          </center>
        </Row>
      </div>
    );
  }
  onQuestionChange(event, question) {
    if (question === 1) {
      if (event.target.checked) {
        this.setState({
          question1: 1,
        });
      } else {
        this.setState({
          question1: 0,
        });
      }
    }
    if (question === 1.1) {
      if (event.target.checked) {
        this.setState({
          question1sub1: 1,
        });
      } else {
        this.setState({
          question1sub1: 0,
        });
      }
    }
    if (question === 2) {
      if (event.target.checked) {
        this.setState({
          question2: 1,
        });
      } else {
        this.setState({
          question2: 0,
        });
      }
    }
    if (question === 3) {
      if (event.target.checked) {
        this.setState({
          question3: 1,
        });
      } else {
        this.setState({
          question3: 0,
        });
      }
    }
  }
  onEmailEdit(event) {
    var validEmail = this.validateEmail(event.target.value);
    this.setState({
      email: event.target.value,
      emailValidate: validEmail,
    });
    if (validEmail) {
      this.setState({
        log: "รูปแบบอีเมลล์ไม่ถูกต้อง",
      });
    } else {
      this.setState({
        log: "",
      });
    }
  }
  validateEmail(email) {
    if (/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email)) {
      return false;
    }
    return true;
  }
  onMobileContact(event) {
    this.setState({
      mobileContact: event.target.value,
    });
  }
  onPriorityChange(value) {
    this.setState({
      priority: value,
    });
  }

  editEmail(event) {
    var obj = {
      controller: "user",
      action: "editprofile",
      identification: this.state.identification,
      email: this.state.email,
    };
    var test = {
      params: JSON.stringify(obj),
      appkey: "3D569F9F115833DD",
    };
    var formData = new FormData();
    for (var name in test) {
      formData.append(name, test[name]);
    }
    fetch("https://api.ttrs.or.th/api.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        this.setState({
          log: "อัพเดทอีเมล์เสร็จสิ้น",
        });
      });
  }
  getUserDetail(event) {
    var obj = {
      controller: "user",
      action: "showdetailusers",
      identification: this.props.identification,
    };
    var test = {
      params: JSON.stringify(obj),
      appkey: "3D569F9F115833DD",
    };
    var formData = new FormData();
    for (var name in test) {
      formData.append(name, test[name]);
    }

    fetch("https://api.ttrs.or.th/api.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.status === "FAIL") {
          this.setState({
            loginStatus: false,
          });
        } else if (data.status === "OK") {
          this.setState({
            loginStatus: true,
            identification: data.userdata.identification,
            name: data.userdata.name,
            lastname: data.userdata.lastname,
            email: data.userdata.email,
            mobile: data.userdata.mobile,
            prefixname: data.userdata.prefixname,
          });
        }
      });
  }
  accessLiveChat(event) {
    if (this.state.platform === "webmobile") {
      ReactDOM.render(
        <ClientAppMobile
          type="reactInternal"
          identification={this.state.identification}
          name={this.state.name}
          lastname={this.state.lastname}
          mobile={this.state.mobile}
          email={this.state.email}
          mobileContact={this.state.mobileContact}
          priority={this.state.priority}
          question1={this.state.question1}
          question1sub1={this.state.question1sub1}
          question2={this.state.question2}
          question3={this.state.question3}
        />,
        document.querySelector(".app"),
      );
    } else {
      ReactDOM.render(
        <ClientApp
          type="reactInternal"
          identification={this.state.identification}
          name={this.state.name}
          lastname={this.state.lastname}
          mobile={this.state.mobile}
          email={this.state.email}
          mobileContact={this.state.mobileContact}
          priority={this.state.priority}
          question1={this.state.question1}
          question1sub1={this.state.question1sub1}
          question2={this.state.question2}
          question3={this.state.question3}
        />,
        document.querySelector(".app"),
      );
    }
  }
}
export default LoginDetail;
