import React, { Component } from "react";
import * as $ from "jquery";
import ChatContent from "./components/chat_content";
import ChatInput from "./components/chat_input";
import NavLivechat from "./components/nav_livechat";
import { Row, Col, Button } from "react-materialize";
import Modal from "react-modal";

const alertAgentAccess = {
  content: {
    top: "20%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

// Create component
class AgentAppRecevingCall extends Component {
  constructor(props) {
    super(props);
    this.messageSend = this.messageSend.bind(this);
    this.state = {
      urlWS: process.env.REACT_APP_URL_WS,
      urlAPI: process.env.REACT_APP_URL_API,
      clientName: null,
      clientLastname: null,
      channel: null,
      threadid: props.threadid,
      identification: props.identification,
      agentid: props.agentid,
      tokenotification: props.token,
      socket: null,
      socketinfo: null,
      threadstatus: null,
      clienthistorylist: null,
      agentMessageTemplate: null,
      modalAlertChat: false,
      modalOpen: false,
      user: "agent",
      data: [],
      datarealtime: [],
      emergency_count: 0,
      clientAlway: 0,
      buttonInputDisabled: false,
      autoScroll: true,
      clientStatus: "",
    };
  }
  componentDidMount() {
    this.addChannel();
    this.clientHistoryList(this.props.identification);
    this.agentMessageTemplate();
  }
  UNSAFE_componentWillMount() {
    Modal.setAppElement("body");
  }
  agentMessageTemplate() {
    fetch(`${this.state.urlAPI}/message/agent/template/list`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        this.setState({
          agentMessageTemplate: data,
        });
      });
  }
  addChannel() {
    fetch(`${this.state.urlAPI}/thread/detail/${this.props.threadid}`, {
      method: "GET",
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        this.setState({ identification: result.identification });
        var obj = {
          controller: "user",
          action: "showdetailusers",
          identification: result.identification,
        };
        var test = {
          params: JSON.stringify(obj),
          appkey: "3D569F9F115833DD",
        };
        $.ajax({
          url: `https://api.ttrs.or.th/api.php`,
          data: test,
          type: "POST",
          dataType: "json",
          success: function (data) {
            const personaldata = {
              identification: result.identification,
              name: result.name,
              lastname: result.lastname,
              mobile: result.mobile,
              mobilecontact: result.mobileContact,
              sender: "agent",
              email: result.email,
              priority: 0,
              userAgent: "",
              platform: "",
              agentid: this.props.agentid,
            };

            fetch(`${this.state.urlAPI}/api/channel/add/receivingcall`, {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
              },
              body: JSON.stringify(personaldata),
            })
              .then((response) => {
                return response.json();
              })
              .then((data) => {
                localStorage.setItem("receviving_threadid", data.threadid);
                this.setState({
                  channel: data.channel,
                  threadid: data.threadid,
                });
                this.getMessage();
              });
          }.bind(this),
        });
      });

    // const personaldata = {
    //     identification : this.props.identification,
    //     name           : this.props.name,
    //     lastname       : this.props.lastname,
    //     mobile         : this.props.mobile,
    //     mobilecontact  : this.props.mobileContact,
    //     sender         : 'agent',
    //     email          : this.props.email,
    //     priority       : 0,
    //     userAgent      : "",
    //     platform       : "",
    //     token          : this.props.token,
    //     mobile_device  : this.props.mobile_device,
    //     agentid        : this.props.agentid
    // }

    // fetch(`${this.state.urlAPI}/api/channel/add/receivingcall`, {
    //     method : 'POST',
    //     headers : {
    //         'Accept' : 'application/json',
    //         'Content-Type' : 'application/json'
    //     },
    //     body : JSON.stringify(personaldata)
    // })
    // .then((response) => {
    //     return response.json();
    // })
    // .then((data) => {
    //     localStorage.setItem("receviving_threadid", data.threadid);
    //     this.setState({
    //         channel  : data.channel,
    //         threadid : data.threadid
    //     });
    //     this.getMessage();
    // });

    // const personaldata = {
    //     identification : this.props.identification,
    //     name           : this.props.name,
    //     lastname       : this.props.lastname,
    //     mobile         : this.props.mobile,
    //     mobilecontact  : this.props.mobileContact,
    //     sender         : 'agent',
    //     email          : this.props.email,
    //     priority       : 0,
    //     userAgent      : "",
    //     platform       : "",
    //     token          : this.props.token,
    //     mobile_device  : this.props.mobile_device,
    //     agentid        : this.props.agentid
    // }

    // fetch(`${this.state.urlAPI}/api/channel/add/receivingcall`, {
    //     method : 'POST',
    //     headers : {
    //         'Accept' : 'application/json',
    //         'Content-Type' : 'application/json'
    //     },
    //     body : JSON.stringify(data)
    // })
    // .then((response) => {
    //     return response.json();
    // })
    // .then((data) => {
    //     localStorage.setItem("receviving_threadid", data.threadid);
    //     this.setState({
    //         channel  : data.channel,
    //         threadid : data.threadid
    //     });
    //     this.getMessage();
    // });
  }
  connectSocket(channel, type = "") {
    const socket = new WebSocket(`${this.state.urlWS}/ws/${channel}`);
    socket.onopen = (evt) => {};
    socket.onmessage = (event) => {
      // console.log(event.data);
      if (type !== "info") {
        var parsedata = JSON.parse(event.data);
        if (typeof parsedata === "object") {
          this.messageChat(parsedata);
        }
      } else {
        var data = JSON.parse(event.data);
        if (data.sender === "automessage") {
          this.setState({ emergency_count: data.emergency });
        } else if (data.sender === "server") {
          this.setClientStatus(data);
        }
      }
    };
    socket.onclose = () => {
      setTimeout(() => {
        this.connectSocket(channel, type);
        // console.log(`Socket reconnected ${channel}`);
      }, 1000);
    };
    return socket;
  }
  setClientStatus(data) {
    if (data.type === "connect") {
      if (data.channel === this.state.channel) {
        this.setState({
          clientStatus: "",
        });
      }
    }
    if (data.type === "close") {
      if (data.channel === this.state.channel) {
        this.setState({
          clientStatus: "หูหนวกขาดการเชื่อมต่อ",
        });
      }
    }
  }
  getMessage() {
    var receviving_threadid = localStorage.getItem("receviving_threadid");
    // this.setState({
    //     channel : this.addChannel()
    // });
    if (receviving_threadid === "null" || receviving_threadid === null) {
      // this.setState({
      //     channel : this.addChannel()
      // });
    } else {
      this.setState({
        threadid: receviving_threadid,
      });

      $.ajax({
        url: `${this.state.urlAPI}/api/message/list/threadid/${receviving_threadid}`,
        type: "GET",
        data: {
          from: this.state.user,
        },
        dataType: "json",
        crossDomain: true,
        success: function (data) {
          console.log(data);
          if (data.threadstatus === "close") {
            this.setState({
              channel: this.addChannel(),
            });
          } else {
            this.setState({
              channel: data.channel,
              threadid: data.threadid,
              identification: data.identification,
              data: data.messagedata,
              threadstatus: data.threadstatus,
              clientName: data.name,
              clientLastname: data.lastname,
              socket: this.connectSocket(data.channel),
              socketinfo: this.connectSocket("OperatorInfo", "info"),
            });
          }
          this.clientHistoryList(data.identification);
          // this.checkClientAlive();
          this.clientCheckLoop();
        }.bind(this),
      });
    }
  }
  clientCheckLoop() {
    setTimeout(() => {
      // this.checkClientAlive();
      this.clientCheckLoop();
    }, process.env.REACT_APP_CHECKALIVEAGENT);
  }

  checkClientAlive() {
    $.ajax({
      url: `${this.state.urlAPI}/api/checkalive/agent`,
      data: {
        threadid: this.state.threadid,
      },
      type: "POST",
      dataType: "json",
      success: function (data) {
        this.setState({
          clientAlway: data.seconds,
        });
      }.bind(this),
    });
  }

  clientHistoryList(identification) {
    $.ajax({
      url: `${this.state.urlAPI}/user/identification/`,
      data: { identification },
      type: "POST",
      dataType: "json",
      crossDomain: true,
      success: function (data) {
        this.setState({
          clienthistorylist: data,
        });
      }.bind(this),
    });
  }
  messageSend(data) {
    if (this.state.socket !== null) {
      this.state.socket.send(JSON.stringify(data));
    }
  }
  messageChat(data) {
    if (data.sender === "automessage" && data.text === "close;") {
      this.setState({
        threadstatus: "close",
      });
    } else if (
      data.realtime === false &&
      data.sender !== "automessage" &&
      data.type !== "welcomeclient"
    ) {
      this.setState({
        data: [...this.state.data, data],
      });
      if (data.sender !== this.state.user) {
        this.setState({
          datarealtime: [],
        });
      }
    } else if (data.sender !== "automessage") {
      if (data.sender !== this.state.user) {
        this.setState({
          datarealtime: data,
        });
      }
    }
  }
  agentAccess(event) {
    this.agentStartChat(true);
    this.setState({
      modalAlertChat: false,
    });
  }

  render() {
    return (
      <div>
        <NavLivechat state={this.state} clientStatus={this.state.clientStatus} />
        <div
          className={
            this.state.threadstatus === "close" ? "close-thread-chatboxcontent" : "chatboxcontent"
          }
        >
          <ChatContent
            autoScroll={this.state.autoScroll}
            user={this.state.user}
            messageChat={this.state.data}
            messageChatRealtime={this.state.datarealtime}
            threadStatus={this.state.threadstatus}
          />
        </div>
        <ChatInput
          urlAPI={this.state.urlAPI}
          user={this.state.user}
          messageSend={this.messageSend}
          threadStatus={this.state.threadstatus}
          agentMessageTemplate={this.state.agentMessageTemplate}
          buttonInputDisabled={this.state.threadstatus === "close" ? true : false}
        />
        <Modal
          isOpen={this.state.modalAlertChat}
          style={alertAgentAccess}
          contentLabel="Alert Agent In Queue"
        >
          <Row>
            <Col s={12}>
              <h4>มีเจ้าหน้าที่กำลังให้บริการ ยืนยันการเข้าห้องสนทนา</h4>
            </Col>
          </Row>
          <Row>
            <Col s={2} />
            <Col s={4}>
              <Button className="green" onClick={(event) => this.agentAccess(event)}>
                เข้าห้องสนทนา
              </Button>
            </Col>
            <Col s={4}>
              <Button
                className="red"
                onClick={() => {
                  window.close();
                }}
              >
                ปิดหน้าต่าง
              </Button>
            </Col>
            <Col s={2} />
          </Row>
        </Modal>
      </div>
    );
  }
}
export default AgentAppRecevingCall;
