import React, { Component } from "react";
import ChatContent from "./components/chat_content";
import ChatInput from "./components/chat_input";
import NavLivechat from "./components/nav_livechat";
import NavLivechatGuest from "./components/nav_livechat_guest";
import { Row, Button } from "react-materialize";
import Modal from "react-modal";
const alertAgentAccess = {
  content: {
    backgroundColor: "white",
    top: "20%",
    left: "10%",
    right: "10%",
    bottom: "30",
  },
};
class AgentApp extends Component {
  constructor(props) {
    super(props);
    this.messageSend = this.messageSend.bind(this);
    this.state = {
      urlWS: process.env.REACT_APP_URL_WS,
      urlAPI: process.env.REACT_APP_URL_API,
      identification: "",
      clientName: null,
      clientLastname: null,
      channel: null,
      uuid: "",
      threadid: props.threadid,
      agentid: props.agentid,
      socket: null,
      socketinfo: null,
      threadstatus: "chat",
      clienthistorylist: null,
      agentMessageTemplate: null,
      latitude: "",
      longitude: "",
      question1: "0",
      question1sub1: "0",
      question2: "0",
      question3: "0",
      modalAlertChat: false,
      modalOpen: false,
      user: "agent",
      data: [],
      datarealtime: [],
      emergency_count: 0,
      clientStatus: "",
      reconnected: "Reconnecting",
      buttonInputDisabled: false,
      autoScroll: true,
      messageComing: false,
    };
    this.audio = new Audio(`${this.state.urlAPI}/sounds/ding.wav`);
    this.audio.preload = "auto";
  }
  togglePlay() {
    this.audio.play();
  }
  componentDidMount() {
    document.title = "ห้อง " + this.props.threadid;
    window.onbeforeunload = confirmExit;
    function confirmExit() {
      return "ปิดหน้าต่างสนทนา";
    }

    this.agentStartChat();
    this.agentMessageTemplate();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.channel !== this.state.channel && this.state.channel !== null) {
      this.connectSocket(this.state.channel);
      this.connectSocket("OperatorInfo", "info");
    }
  }

  UNSAFE_componentWillMount() {
    Modal.setAppElement("body");
  }
  connectSocket(channel, type = "") {
    const socket = new WebSocket(`${this.state.urlWS}/ws/${channel}`);
    socket.onopen = () => {
      if (channel === "OperatorInfo") {
        this.setState({
          socketinfo: socket,
        });
      } else {
        this.getMessage();
        this.setState({
          socket: socket,
        });
      }
      this.setState({
        reconnected: "",
      });
    };
    socket.onmessage = (event) => {
      var data = JSON.parse(event.data);
      if (type !== "info") {
        if (typeof data === "object") {
          this.messageChat(data);
        }
        if (data.type === "clientdisconnect") {
          this.setClientStatus(data);
        }
      } else {
        if (data.sender === "automessage") {
          this.setState({ emergency_count: data.emergency });
        }
      }
    };
    socket.onclose = () => {
      // handle นี้
      this.setState({
        reconnected: "Reconnecting",
      });

      // alert("GET Message")
      setTimeout(() => {
        this.connectSocket(channel, "reconnect");
        console.log("Reconnecting");
      }, 2000);
    };
  }
  handleScroll(ev) {
    let element = ev.target;
    if (element.scrollHeight - element.scrollTop - 200 <= element.clientHeight) {
      if (!this.state.autoScroll) {
        this.setState({ autoScroll: true, messageComing: false });
      }
    } else {
      if (this.state.autoScroll) {
        this.setState({ autoScroll: false });
      }
    }
  }
  setClientStatus(data) {
    if (data.type === "connect") {
      if (data.channel === this.state.channel) {
        this.setState({
          clientStatus: "",
        });
      }
    } else {
      if (data.text === "หูหนวกขาดการเชื่อมต่อ") {
        this.togglePlay();
        this.setState({
          clientStatus: "หูหนวกขาดการเชื่อมต่อ",
        });
      }
    }
  }
  agentStartChat(agentTakeOver) {
    var personaldata;
    if (this.props.agentid !== "" || this.props.agentid !== null) {
      personaldata = {
        agentid: this.props.agentid,
        agentTakeOver,
      };
    } else {
      var name = prompt("กรอกชื่อเจ้าหน้าที่");
      personaldata = {
        agentname: name,
      };
    }

    const threadid = this.state.threadid.replace(/[^0-9]+/g, "");
    fetch(`${this.state.urlAPI}/api/thread/chat/${threadid}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(personaldata),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.status === "FAIL" && data.message === "no agent in database") {
          alert("ไม่พบชื่อเจ้าหน้าที่ในระบบ");
          window.close();
          return;
        }
        if (data.status === "NO DATA") {
          alert("ไม่พบข้อมูลในระบบ");
          window.close();
          return;
        }
        if (
          data.threadstatus === "chat" &&
          data.agentid.toString() !== this.props.agentid.toString()
        ) {
          this.setState({
            threadstatus: "close",
            modalAlertChat: true,
            agentname: data.agentid,
          });
        } else if (data.threadstatus === "close" || data.threadstatus === "abandon") {
          this.setState({
            threadstatus: "close",
          });
          this.getMessage();
        } else {
          this.setState({
            agentname: data.agentid,
            agentid: data.agentid,
          });
          this.getMessage();
        }
        this.setState({
          emergency_count: data.emergency_count,
        });
      });
  }
  getMessage() {
    fetch(`${this.state.urlAPI}/agent/getname`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: this.props.agentid,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((localAgent) => {
        fetch(
          `${this.state.urlAPI}/api/message/list/threadid/${this.state.threadid}?from=${this.state.user}`,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          },
        )
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            if (localAgent.agentname === data.agentname) {
              if (data.threadstatus === "abandon") {
                data.threadstatus = "close";
              }
              data.messagedata.push({
                sender: "system",
                realtime: false,
                text: "getMessageComplete",
              });
              this.setState({
                channel: data.channel,
                threadid: data.threadid,
                identification: data.identification,
                latitude: data.lattitude,
                longitude: data.longitude,
                data: data.messagedata,
                threadstatus: data.threadstatus,
                clientName: data.name,
                clientLastname: data.lastname,
                question1: data.question1,
                question1sub1: data.question1sub1,
                question2: data.question2,
                question3: data.question3,
                uuid: data.uuid,
                // socket         : this.connectSocket(data.channel),
                // socketinfo     : this.connectSocket("OperatorInfo", "info")
              });
              // console.log(data)
              if (data.uuid === "") {
                this.setState({
                  clientStatus: "หูหนวกขาดการเชื่อมต่อ",
                  uuid: "",
                });
              }
              this.clientHistoryList(data.identification);
              this.clientCheckLoop();
            } else {
              this.setState({
                threadstatus: "close",
                modalAlertChat: true,
                agentname: data.agentname,
              });
            }
          });
      });
  }
  clientCheckLoop() {
    setTimeout(() => {
      this.clientCheckLoop();
    }, process.env.REACT_APP_CHECKALIVEAGENT);
  }

  clientHistoryList(identification) {
    fetch(`${this.state.urlAPI}/user/identification/`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        identification: identification,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        this.setState({
          clienthistorylist: data,
        });
      });
  }
  agentMessageTemplate() {
    fetch(`${this.state.urlAPI}/message/agent/template/list`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        this.setState({
          agentMessageTemplate: data,
        });
      });
  }
  messageSend(data) {
    if (this.state.socket !== null) {
      this.state.socket.send(JSON.stringify(data));
    }
  }
  messageChat(data) {
    // console.log(data);
    if (data.sender === "automessage" && data.text === "close;") {
      this.setState({
        threadstatus: "close",
      });
    } else if (data.sender === "automessage" && data.text === "clientconnect") {
      // client recconnect
    } else if (data.realtime === false) {
      this.setState(
        {
          data: [...this.state.data, data],
        },
        () => {
          if (this.state.autoScroll === true) {
            this.setState({ messageComing: false });
          } else {
            this.setState({ messageComing: true });
          }
        },
      );
      if (data.sender !== this.state.user) {
        this.setState({
          datarealtime: [],
        });
      }
    } else if (data.sender !== "automessage") {
      if (data.sender !== this.state.user) {
        this.setState({
          datarealtime: data,
        });
      }
    }
  }
  agentAccess(event) {
    this.agentStartChat(true);
    this.setState({
      modalAlertChat: false,
    });
  }
  render() {
    return (
      <div>
        {this.state.reconnected === "Reconnecting" ? (
          <div className="agent-reconnect">
            <div className="lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            กำลังเชื่อมต่อ
          </div>
        ) : null}

        {this.state.modalAlertChat ? (
          <NavLivechatGuest state={this.state} />
        ) : (
          <NavLivechat state={this.state} clientStatus={this.state.clientStatus} />
        )}
        <div
          ref="list"
          onScroll={this.handleScroll.bind(this)}
          className={
            this.state.threadstatus === "close" ? "close-thread-chatboxcontent" : "chatboxcontent"
          }
        >
          <div
            align="center"
            className={
              this.state.autoScroll === false && this.state.messageComing === true
                ? "scroll-down"
                : "scroll-down hide"
            }
          >
            <Button
              className="btn btn-primary"
              style={{
                borderRadius: "20px",
                height: "40px",
                width: "200px",
                backgroundColor: "#45b75a",
              }}
            >
              มีข้อความเข้า
            </Button>
          </div>
          <ChatContent
            autoScroll={this.state.autoScroll}
            user={this.state.user}
            messageChat={this.state.data}
            messageChatRealtime={this.state.datarealtime}
            threadStatus={this.state.threadstatus}
          />
        </div>
        <ChatInput
          urlAPI={this.state.urlAPI}
          user={this.state.user}
          messageSend={this.messageSend}
          threadStatus={this.state.threadstatus}
          agentMessageTemplate={this.state.agentMessageTemplate}
          buttonInputDisabled={this.state.threadstatus === "close"}
        />
        <Modal
          isOpen={this.state.modalAlertChat}
          style={alertAgentAccess}
          contentLabel="Alert Agent In Queue"
        >
          <Row>
            <div align="center">
              <h5>เจ้าหน้าที่ {this.state.agentname} กำลังให้บริการในห้องสนทนา </h5>
            </div>
          </Row>
          <Row>
            <div align="center">
              <Button
                className="red"
                onClick={() => {
                  window.close();
                }}
              >
                ปิดหน้าต่าง
              </Button>
            </div>
            {/* <Col s={4} /> */}
            {/* <Col s={4}>
                            <Button
                                className="green"
                                onClick={event=>this.agentAccess(event)}
                            >เข้าห้องสนทนา</Button>
                        </Col> */}
            {/* <Col s={5}> */}

            {/* </Col> */}
            {/* <Col s={2} /> */}
          </Row>
        </Modal>
      </div>
    );
  }
}
export default AgentApp;
