import React, { Component } from "react";
import * as $ from "jquery";
import ChatContent from "./components/chat_content";
import ChatInput from "./components/chat_input";
import NavLivechat from "./components/nav_livechat";
import { Row, Col, Button } from "react-materialize";
import Modal from "react-modal";

const alertAgentAccess = {
  content: {
    top: "20%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

// Create component
class AgentV3RecevingCall extends Component {
  constructor(props) {
    super(props);
    this.messageSend = this.messageSend.bind(this);
    this.state = {
      clientName: null,
      clientLastname: null,
      channel: null,
      threadid: props.threadid,
      identification: props.identification,
      agentid: props.agentid,
      tokenotification: props.token,
      threadstatus: null,
      clienthistorylist: null,
      agentMessageTemplate: null,
      modalAlertChat: false,
      modalOpen: false,
      user: "agent",
      data: [],
      datarealtime: [],
      emergency_count: 0,
      clientAlway: 0,
      buttonInputDisabled: false,
      clientStatus: "",
      autoScroll: true,
      socket: null,
      socketinfo: null,
      messageComing: false,
    };
  }
  componentDidMount() {
    this.agentStartChat();
    this.clientHistoryList(this.props.identification);
    this.agentMessageTemplate();
    this.automessageTimeout();
  }
  UNSAFE_componentWillMount() {
    Modal.setAppElement("body");
  }
  componentDidUpdate(prevProps, prevState) {
    // console.log(this.state.modalAlertChat)
    if (prevState.channel !== this.state.channel && this.state.channel !== null) {
      this.setState({
        socket: this.connectSocket(this.state.channel),
        socketinfo: this.connectSocket("OperatorInfo", "info"),
      });
    }
  }
  handleScroll(ev) {
    let element = ev.target;
    if (element.scrollHeight - element.scrollTop - 200 <= element.clientHeight) {
      if (!this.state.autoScroll) {
        this.setState({ autoScroll: true, messageComing: false });
      }
    } else {
      if (this.state.autoScroll) {
        this.setState({ autoScroll: false });
      }
    }
  }
  agentMessageTemplate() {
    fetch(`${process.env.REACT_APP_URL_API}/v3/agent/message/agent/template/list`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        this.setState({
          agentMessageTemplate: data,
        });
      });
  }
  connectSocket(channel, type = "") {
    const socket = new WebSocket(`${process.env.REACT_APP_URL_WS}/ws/${channel}`);
    socket.onopen = (evt) => {};
    socket.onmessage = (event) => {
      // console.log(event.data);
      if (type !== "info") {
        var parsedata = JSON.parse(event.data);
        if (typeof parsedata === "object") {
          this.messageChat(parsedata);
        }
      } else {
        var data = JSON.parse(event.data);
        if (data.sender === "automessage") {
          this.setState({ emergency_count: data.emergency });
        } else if (data.sender === "server") {
          this.setClientStatus(data);
        }
      }
    };
    socket.onclose = () => {
      setTimeout(() => {
        this.connectSocket(channel, type);
        console.log(`Socket reconnected ${channel}`);
      }, 1000);
    };
    return socket;
  }
  automessageTimeout() {
    setTimeout(() => {
      if (this.state.socket !== null) {
        if (this.state.threadstatus !== "chat_receiving" && this.state.threadstatus !== "close") {
          this.state.socket.send(
            JSON.stringify({
              channel: this.state.channel,
              sender: "agent",
              realtime: false,
              text: "หูหนวกไม่รับสาย",
            }),
          );
        }
      }
      this.automessageTimeout();
    }, 60000);
  }

  setClientStatus(data) {
    if (data.type === "connect") {
      if (data.channel === this.state.channel) {
        this.setState({
          clientStatus: "",
        });
      }
    }
    if (data.type === "close") {
      if (data.channel === this.state.channel) {
        this.setState({
          clientStatus: "หูหนวกขาดการเชื่อมต่อ",
        });
      }
    }
  }
  getMessage() {
    fetch(
      `${process.env.REACT_APP_URL_API}/v3/agent/message/list/threadid/${this.props.threadid}?from=agent`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      },
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        // console.log(data)
        if (data.status !== "no result") {
          this.setState({
            data: data.messagedata,
          });
        }
      });
  }
  clientCheckLoop() {
    setTimeout(() => {
      // this.checkClientAlive();
      this.clientCheckLoop();
    }, process.env.REACT_APP_CHECKALIVEAGENT);
  }

  checkClientAlive() {
    $.ajax({
      url: `${process.env.REACT_APP_URL_API}/api/checkalive/agent`,
      data: {
        threadid: this.state.threadid,
      },
      type: "POST",
      dataType: "json",
      success: function (data) {
        this.setState({
          clientAlway: data.seconds,
        });
      }.bind(this),
    });
  }

  clientHistoryList(identification) {
    $.ajax({
      url: `${process.env.REACT_APP_URL_API}/user/identification/`,
      data: { identification },
      type: "POST",
      dataType: "json",
      crossDomain: true,
      success: function (data) {
        this.setState({
          clienthistorylist: data,
        });
      }.bind(this),
    });
  }

  notificationSend() {
    fetch(`${process.env.REACT_APP_URL_API}/v3/sendnotification/receiving/`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
      body: JSON.stringify({
        token: this.props.token,
        mobile_device: this.props.mobile_device,
        threadid: this.props.threadid,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then(() => {});
  }

  messageSend(data) {
    if (this.state.socket !== null) {
      this.state.socket.send(JSON.stringify(data));
    }
  }
  messageChat(data) {
    try {
      if (data.type === "recevingclientjoin") {
        this.setState({
          threadstatus: "chat_receiving",
        });
      }
    } catch (error) {}
    if (data.sender === "automessage" && data.text === "close;") {
      this.setState({
        threadstatus: "close",
      });
    } else if (
      data.realtime === false &&
      data.sender !== "automessage" &&
      data.type !== "welcomeclient"
    ) {
      this.setState(
        {
          data: [...this.state.data, data],
        },
        () => {
          if (this.state.autoScroll === true) {
            this.setState({ messageComing: false });
          } else {
            this.setState({ messageComing: true });
          }
        },
      );
      if (data.sender !== this.state.user) {
        this.setState({
          datarealtime: [],
        });
      }
    } else if (data.sender !== "automessage") {
      if (data.sender !== this.state.user) {
        this.setState({
          datarealtime: data,
        });
      }
    }
  }
  agentAccess(event) {
    this.agentStartChat(true);
    this.setState({
      modalAlertChat: false,
    });
  }
  agentStartChat(agentTakeOver) {
    var personaldata;
    if (this.props.agentid !== "" || this.props.agentid !== null) {
      personaldata = {
        agentid: this.props.agentid,
        agentTakeOver,
        name: this.props.name,
        lastname: this.props.lastname,
        identification: this.props.identification,
      };
    } else {
      var name = prompt("กรอกชื่อเจ้าหน้าที่");
      personaldata = {
        agentname: name,
        name: this.props.name,
        lastname: this.props.lastname,
        identification: this.props.identification,
      };
    }

    fetch(`${process.env.REACT_APP_URL_API}/v3/thread/chatreceiving/${this.props.threadid}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
      body: JSON.stringify(personaldata),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        this.setState({
          channel: data.detail.channel,
          threadid: data.detail.threadid,
          identification: data.detail.identification,
          threadstatus: data.detail.status,
          clientName: data.detail.name,
          clientLastname: data.detail.lastname,
        });
        this.clientHistoryList(data.detail.identification);
        this.clientCheckLoop();
        this.notificationSend();
        this.getMessage();
        // if(data.threadstatus === "chat_receiving" && data.agentid.toString() !== this.props.agentid.toString()){
        //     this.setState({
        //         threadstatus : "close",
        //         modalAlertChat  : true,
        //         agentname : data.agentname
        //     });
        // }else if (data.threadstatus === "close" || data.threadstatus === "abandon"){
        //     this.setState({
        //         threadstatus : "close"
        //     });
        // }else{
        //     this.setState({
        //         agentname : data.agentname,
        //         agentid   : data.agentid
        //     });
        // }
        // this.setState({
        //     emergency_count : data.emergency_count
        // });
      });
  }
  render() {
    return (
      <div>
        <NavLivechat state={this.state} clientStatus={this.state.clientStatus} />
        <div
          ref="list"
          onScroll={this.handleScroll.bind(this)}
          className={
            this.state.threadstatus === "close" ? "close-thread-chatboxcontent" : "chatboxcontent"
          }
        >
          <ChatContent
            user={this.state.user}
            messageChat={this.state.data}
            messageChatRealtime={this.state.datarealtime}
            threadStatus={this.state.threadstatus}
            autoScroll={this.state.autoScroll}
          />
        </div>

        <ChatInput
          urlAPI={process.env.REACT_APP_URL_API}
          user={this.state.user}
          messageSend={this.messageSend}
          threadStatus={this.state.threadstatus}
          agentMessageTemplate={this.state.agentMessageTemplate}
          buttonInputDisabled={this.state.threadstatus === "close" ? true : false}
        />
        <Modal
          isOpen={this.state.modalAlertChat}
          style={alertAgentAccess}
          contentLabel="Alert Agent In Queue"
        >
          <Row>
            <Col s={12}>
              <h4>มีเจ้าหน้าที่กำลังให้บริการ ยืนยันการเข้าห้องสนทนา</h4>
            </Col>
          </Row>
          <Row>
            <Col s={2} />
            <Col s={4}>
              <Button className="green" onClick={(event) => this.agentAccess(event)}>
                เข้าห้องสนทนา
              </Button>
            </Col>
            <Col s={4}>
              <Button
                className="red"
                onClick={() => {
                  window.close();
                }}
              >
                ปิดหน้าต่าง
              </Button>
            </Col>
            <Col s={2} />
          </Row>
        </Modal>
      </div>
    );
  }
}
export default AgentV3RecevingCall;
