import React, { Component } from "react";
import { Button } from "react-materialize";
// import * as $ from 'jquery';
var searchTableHeader, searchTableData;

class RecevingSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    if (this.props.searchData) {
      if (this.props.searchFrom === "ttrs") {
        searchTableHeader = (
          <thead className="#eeeeee grey lighten-3">
            <tr>
              <th>บัตรประชาชน</th>
              <th>คำนำหน้าชื่อ</th>
              <th>ชื่อ-นามสกุล</th>
              <th>โทร</th>
            </tr>
          </thead>
        );
        searchTableData = this.props.searchData.map((listItem, index) => {
          if (listItem) {
            return (
              <tr key={index}>
                <td>{listItem.identification}</td>
                <td>{listItem.prefixname}</td>
                <td>
                  {listItem.name} {listItem.lastname}
                </td>
                <td>
                  <Button onClick={(event) => this.openChat(listItem)}>Call</Button>
                </td>
              </tr>
            );
          } else {
            return <div></div>;
          }
        });
      } else if (this.props.searchFrom === "livechat") {
        searchTableHeader = (
          <thead className="#eeeeee grey lighten-3">
            <tr>
              {/* <th>ความสำคัญ</th> */}
              <th>บัตรประชาชน</th>
              <th>ชื่อ-สกุล หูหนวก</th>
              <th>เบอร์หูดี</th>
              <th>ติดต่อล่าสุด</th>
              {/* <th>สถานะ</th> */}
              <th>โทร</th>
            </tr>
          </thead>
        );
        searchTableData = this.props.searchData.map((listItem, index) => {
          if (listItem) {
            return (
              <tr key={index} className={this.priorityColor(listItem.priority)}>
                {/* <td>
                                    {this.priorityName(listItem.priority)}
                                </td> */}
                <td>{listItem.identification}</td>
                <td>
                  {listItem.name} {listItem.lastname}
                </td>
                <td>{listItem.mobilecontact}</td>
                <td>{listItem.dtmcreated}</td>
                {/* <td>
                                    {this.statusColor(listItem.status)}
                                </td> */}
                <td>
                  <Button
                    style={{ backgroundColor: "#348de5" }}
                    onClick={(event) => this.openChat(listItem)}
                  >
                    สร้างห้องสนทนา
                  </Button>
                </td>
              </tr>
            );
          } else {
            return <div></div>;
          }
        });
      }
    }
    return (
      <div>
        <table className="centered highlight bordered" style={{ border: "1px solid #eae3e3" }}>
          {searchTableHeader}
          <tbody>{searchTableData}</tbody>
        </table>
      </div>
    );
  }
  priorityName(priority) {
    if (priority === "1") {
      return "ฉุกเฉิน";
    } else {
      return "ปกติ";
    }
  }
  priorityColor(priority) {
    if (priority === "1") {
      return "receiving-emergency-list";
    }
    return "";
  }
  statusColor(status) {
    if (status === "chat") {
      return <font color="#423EFF">{status}</font>;
    } else if (status === "abandon") {
      return <font color="#F10E0F">{status}</font>;
    } else if (status === "serviceoffline") {
      return <font color="#423EFF">{status}</font>;
    } else if (status === "close") {
      return <font color="#228A12">{status}</font>;
    }
  }
  openChat(listItem) {
    window.open(
      `/receiving/n/agent/${this.props.agentid}/t/${listItem.threadid}`,
      "popup",
      `height=800,width=1300,left=10,top=10,resizable=yes,scrollbars=yes,' + 'menubar=no,location=no,directories=no,status=yes`,
    );
  }
}
export default RecevingSearch;
