import React from "react";
import { AuthProvider } from "oidc-react";

const Test = () => {
  const oidcConfig = {
    onSignIn: () => {
      console.log("Onsign");
      // updateAccessToken();
      window.location.hash = "";
    },
    authority: process.env.REACT_APP_ISSUER_AGENT,
    clientId: process.env.REACT_APP_CLIENT_ID_AGENT,
    redirectUri: `${process.env.REACT_APP_DOMAIN}`,
  };

  return (
    <>
      <AuthProvider {...oidcConfig}>
        <div>Hello</div>
      </AuthProvider>
    </>
  );
};
export default Test;
