import React, { Component } from "react";
import { timestamp } from "../function/timestamp";

class ChatMessageRealtime extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  scrollToBottom = (behavior) => {
    this.messagesEnd.scrollIntoView({ behavior });
  };
  componentDidMount() {
    if (this.props.user !== "history") {
      this.scrollToBottom("instant");
    }
  }
  componentDidUpdate() {
    // this.scrollToBottom("smooth");
  }
  render() {
    if (this.props.user === "history") {
      return <div></div>;
    } else if (
      this.props.messageChatRealtime.text === "" ||
      this.props.messageChatRealtime.length === 0
    ) {
      return (
        <div
          ref={(el) => {
            this.messagesEnd = el;
          }}
        ></div>
      );
    } else {
      return (
        <div
          id={this.props.id}
          className="msg-row"
          ref={(el) => {
            this.messagesEnd = el;
          }}
        >
          <div className="msg-left-messagedata message">
            {" "}
            {this.props.messageChatRealtime.text}{" "}
          </div>
          <span className="msg-time-right">{timestamp()} น.</span>
        </div>
      );
    }
  }
}
export default ChatMessageRealtime;
