import React, { Component } from "react";
import Modal from "react-modal";
import TypeMessageItem from "./type_message_item";
import axios from "axios";
const fetch = require("fetch-retry")(global.fetch);

const modalStyles = {
  content: {
    width: "60vh",
    top: "40%",
    left: "47%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

class CloseRoom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      threadid: props.closedata.state.threadid,
      modalIsOpen: false,
      chatcomplete: "1",
      typemessage: null,
      typemessage_incomplete: null,
      typemessagedata_complete: null,
      typemessagedata_incomplete: null,
      typemessagedata_noservice: null,
      is_emergency: 0,
      isTypeUpdate: false,
      edit: false,
      closeBtnText: "จบการสนทนา",
      closeBtnClass: "close-thread-btn",
    };
  }
  async componentDidMount() {
    if (this.props.closedata.state.user === "agent") {
      this.getTypeMessage();
    }
    if (this.props.closedata.state.user === "history") {
      this.getTypeMessage();
      this.setState({
        closeBtnText: "แก้ไขการรับเรื่อง",
        closeBtnClass: "edit-thread-typemessage",
      });
    }
  }
  componentDidUpdate(previousProps, previousState) {
    if (previousProps.closedata.chatcomplete !== this.props.closedata.chatcomplete) {
      // console.log(this.props.closedata)
      this.setState({
        chatcomplete: this.props.closedata.chatcomplete,
        typemessage: this.props.closedata.typemessage,
        is_emergency: this.props.closedata.is_emergency,
      });
    }
    if (previousProps.closedata.edit !== this.props.closedata.edit) {
      if (this.props.closedata.edit === "disable") {
        this.setState({
          edit: true,
        });
      }
    }
    if (this.props.closedata.state.threadstatus === "close") {
      console.log("state close", "REACT_APP_RATE_ENABLED", process.env.REACT_APP_RATE_ENABLED);
      if (process.env.REACT_APP_URL_RATE_API === "") {
        return;
      }
      if (process.env.REACT_APP_RATE_ENABLED === "true") {
        if (this.props.closedata.state.user === "client") {
          console.log("state user client");
          if (this.props.closedata.state.threadtype !== "clientblock") {
            const CreateLink = async () => {
              const response = await axios.post(
                `${process.env.REACT_APP_URL_RATE_API}/createlink`,
                {
                  icrm_id: this.props.closedata.state.threadid,
                  service_id: 3,
                },
              );
              window.location = response.data.url;
            };
            CreateLink();
          }
        }
      }
    }
  }
  UNSAFE_componentWillMount() {
    Modal.setAppElement("body");
  }

  handleRadioComlete = (event) => {
    if (event.target.value === "1") {
      this.setState({
        chatcomplete: event.target.value,
        typemessage: this.state.typemessagedata_complete[0].type_id,
      });
    } else if (event.target.value === "0") {
      this.setState({
        chatcomplete: event.target.value,
        typemessage: this.state.typemessagedata_incomplete[0].type_id,
      });
    } else if (event.target.value === "2") {
      this.setState({
        chatcomplete: event.target.value,
        typemessage: this.state.typemessagedata_noservice[0].type_id,
      });
    }
  };
  handleRealEmergency = (event) => {
    const res = event.target.checked ? 1 : 0;
    this.setState({
      is_emergency: res,
    });
  };

  getTypeMessage() {
    fetch(`${process.env.REACT_APP_URL_API}/api/saveTypeMessage/viewtypemessage`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        this.setState({
          typemessagedata_complete: data.completeType,
          typemessagedata_incomplete: data.incompleteType,
          typemessagedata_noservice: data.noserviceType,
          typemessage: 30,
        });
      });
  }
  typeMessage(event) {
    this.setState({
      typemessage: event.target.value,
    });
  }
  closeThread() {
    if (
      this.props.closedata.state.user === "history" ||
      this.props.closedata.state.threadstatus === "close"
    ) {
      this.setState({ modalIsOpen: true });
      return;
    }

    this.setState({
      closeBtnText: "กรุณารอสักครู่",
    });

    const sender = this.props.closedata.state.user;
    const threadid = this.props.closedata.state.threadid;

    fetch(`${process.env.REACT_APP_URL_API}/thread/close`, {
      retries: 20,
      retryDelay: 1500,
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        sender: sender,
        threadid: threadid,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        if (json.status === "thread close") {
          if (
            this.props.closedata.state.user === "agent" ||
            this.props.closedata.state.user === "history"
          ) {
            this.setState({ modalIsOpen: true });
          }
        }
        this.setState({ closeBtnText: "จบการสนทนา" });
      });
  }
  selectTypeAction(event) {
    window.onbeforeunload = null;
    if (this.state.disabled === false) {
      window.close();
    } else {
      fetch(`${process.env.REACT_APP_URL_API}/api/saveTypeMessage/`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          threadid: this.props.closedata.state.threadid,
          chatcomplete: this.state.chatcomplete,
          typemessage: this.state.typemessage,
          is_emergency: this.state.is_emergency,
          agentid: this.props.closedata.state.agentid,
        }),
      })
        .then((response) => {
          return response.json();
        })
        .then(() => {
          this.setState({
            modalIsOpen: false,
            isTypeUpdate: true,
          });

          if (this.props.closedata.state.user === "history") {
            window.location.reload();
          } else {
            window.close();
          }
        });
    }
  }

  render() {
    var typemessagedata,
      selectTypeMessageItem = "<option></option>";
    if (this.state.chatcomplete === "1") {
      typemessagedata = this.state.typemessagedata_complete;
    } else if (this.state.chatcomplete === "0") {
      typemessagedata = this.state.typemessagedata_incomplete;
    } else {
      typemessagedata = this.state.typemessagedata_noservice;
    }
    if (typemessagedata) {
      selectTypeMessageItem = typemessagedata.map((listItem, index) => {
        if (listItem) {
          return (
            <TypeMessageItem
              key={index}
              listItem={listItem}
              chatcomplete={this.state.chatcomplete}
            />
          );
        } else {
          return <option></option>;
        }
      });
    }
    return (
      <div>
        <a
          href={() => false}
          className={this.state.closeBtnClass}
          onClick={(event) => this.closeThread(event)}
          disabled={this.props.closedata.state.threadstatus === "close" ? "disabled" : ""}
        >
          {this.state.closeBtnText}
        </a>
        <Modal style={modalStyles} isOpen={this.state.modalIsOpen} contentLabel="SelectTypeMessage">
          <h5>ข้อมูลการรับเรื่อง</h5>
          <hr />
          <div className="row" style={{ marginBottom: "10px", marginTop: "20px" }}>
            <div>
              <b>การรับเรื่อง</b>
            </div>
            <p>
              <label>
                <input
                  className="typecompleteradio"
                  name="checkbox"
                  type="checkbox"
                  onChange={this.handleRealEmergency}
                  checked={this.state.is_emergency ? true : false}
                />
                <span className="typecompletetext">
                  <img
                    className="typerealemergency"
                    src={require(`../images/emergency_ico.png`)}
                    alt="isEmergency"
                  ></img>
                  ฉุกเฉินจริง
                </span>
              </label>
            </p>
            <div style={{ marginTop: "30px" }}>
              <b>สถานะรับเรื่อง</b>
            </div>
            <p>
              <label>
                <input
                  className="typecompleteradio"
                  name="completegroup1"
                  type="radio"
                  onChange={this.handleRadioComlete}
                  value="1"
                  checked={this.state.chatcomplete === "1"}
                />
                <span className="typecompletetext">สนทนาสำเร็จ</span>
              </label>
            </p>
            <p>
              <label>
                <input
                  className="typecompleteradio"
                  name="completegroup1"
                  type="radio"
                  onChange={this.handleRadioComlete}
                  value="0"
                  checked={this.state.chatcomplete === "0"}
                />
                <span className="typecompletetext">สนทนาไม่สำเร็จ</span>
              </label>
            </p>
            <p>
              <label>
                <input
                  className="typecompleteradio"
                  name="completegroup1"
                  type="radio"
                  onChange={this.handleRadioComlete}
                  value="2"
                  checked={this.state.chatcomplete === "2"}
                />
                <span className="typecompletetext">ไม่ให้บริการ</span>
              </label>
            </p>
          </div>
          <div>
            <b>ประเภทการรับเรื่อง</b>
          </div>

          <div className="row">
            <select
              className="browser-default select-type"
              onChange={(event) => this.typeMessage(event)}
              value={this.state.typemessage}
            >
              {selectTypeMessageItem}
            </select>
          </div>
          <div align="center">
            <button className="btn btn-primary" onClick={(event) => this.selectTypeAction(event)}>
              {this.state.edit === false ? "บันทึก" : "ยกเลิก"}
            </button>
          </div>
        </Modal>
      </div>
    );
  }
}

export default CloseRoom;
