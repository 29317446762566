import React, { Component } from "react";
import ChatContent from "./components/chat_content";
import HistoryHeader from "./components/history_header";
import NavLivechat from "./components/nav_livechat_history";

class HistoryApp extends Component {
  constructor(props) {
    super();
    this.state = {
      urlAPI: process.env.REACT_APP_URL_API,
      threadid: props.threadid,
      threadstatus: "close",
      user: "history",
      data: [],
    };
  }
  componentDidMount() {
    this.getMessage();
  }

  getMessage() {
    fetch(`${this.state.urlAPI}/api/message/list/threadid/${this.state.threadid}?from=history`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        this.setState({
          data,
        });
      })
      .then(() => {
        fetch(`${this.state.urlAPI}/api/saveTypeMessage/viewtypemessagebyid`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            chatcomplete: this.state.data.chatcomplete,
            typemessage: this.state.data.typemessage,
          }),
        })
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            this.setState({
              chatcomplete: data.chatcomplete,
              typemessage: data.typemessage,
              edit: data.edit,
            });
          });
      });
  }

  render() {
    return (
      <div className="history">
        <NavLivechat
          state={this.state}
          clientStatus={this.state.clientStatus}
          chatcomplete={this.state.data.chatcomplete}
          typemessage={this.state.data.typemessage}
          is_emergency={this.state.data.is_emergency}
          edit={this.state.edit}
        />
        <HistoryHeader
          level="sup"
          urlAPI={this.state.urlAPI}
          historydata={this.state.data}
          chatcomplete={this.state.chatcomplete}
          typemessage={this.state.typemessage}
        />
        <br />
        <div className="chatboxcontent-history">
          <ChatContent user={this.state.user} messageChat={this.state.data.messagedata} />
        </div>
      </div>
    );
  }
}
export default HistoryApp;
