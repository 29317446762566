import React from "react";
export default function Main() {
  return (
    <>
      <head>
        <title>TTRS LiveChat Web</title>
      </head>
      <main className="center">
        <div className="welcome">TTRS LiveChat</div>
      </main>
    </>
  );
}
