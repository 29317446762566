import React, { Component } from "react";
import CloseRoom from "./close_room";

class NavLivechat extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <nav className="chatboxheader transparent">
        <div className="nav-wrapper nav-livechat">
          <ul>
            <li>
              <a href={() => false} className="nav-reconnecting">
                {this.props.state.reconnected}
              </a>
            </li>
          </ul>
          <ul id="nav-mobile" className="right hide-on-med-and-down nav-livechat-component">
            <li>
              <CloseRoom closedata={this.props} />
            </li>
          </ul>
        </div>
      </nav>
    );
  }
}

export default NavLivechat;
