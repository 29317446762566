import React, { Component } from "react";
import { Row, Col, Button } from "react-materialize";
import { RadioGroup, ReversedRadioButton } from "react-radio-buttons";
import ClientApp from "../client";
import ClientAppMobile from "../client_mobile";
import styled from "styled-components";
import ReactDOM from "react-dom";
import { AuthProvider } from "oidc-react";

const StyledRow = styled(Row)`
  margin-bottom: 0;
  line-height: 0;
`;
var loginHeader = "";

class LoginDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileContact: "",
      priority: "0",
      priorityCheckNormal: true,
      priorityCheckEmergency: false,
      detailStlye: "rgb(125, 124, 124)",
      identification: "",
      name: "",
      lastname: "",
      email: "",
      prefixname: "",
      log: "",
      emailTextBtn: "อัพเดท",
      question1: 0,
      question1sub1: 0,
      question2: 0,
      question3: 0,
      questionClass: 3,
      platform: "",
      loginStatus: false,
      timeout: true,
      token_id: "",
      token: "",
      oidcConfig: {
        onSignIn: () => {
          this.setState({
            token: JSON.parse(
              sessionStorage.getItem(
                `oidc.user:${process.env.REACT_APP_ISSUER}:${process.env.REACT_APP_CLIENT_ID}`,
              ),
            ).access_token,
          });
          var url = document.location.href;
          window.history.pushState({}, "", url.split("?")[0]);
        },
        authority: process.env.REACT_APP_ISSUER,
        clientId: process.env.REACT_APP_CLIENT_ID,
        redirectUri: `${process.env.REACT_APP_DOMAIN}/v3/normal`,
      },
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.token !== this.state.token) {
      try {
        var session_token = JSON.parse(
          sessionStorage.getItem(
            `oidc.user:${process.env.REACT_APP_ISSUER}:${process.env.REACT_APP_CLIENT_ID}`,
          ),
        ).access_token;
        this.setState({
          token: session_token,
        });
      } catch (error) {}
      fetch(`${process.env.REACT_APP_URL_API}/v3/getdetailfromtoken/`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: session_token,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status === "OK") {
            this.setState({
              identification: data.data.preferred_username,
              name: data.data.given_name,
              lastname: data.data.family_name,
              token_id: data.data.sub,
              loginStatus: true,
            });
            this.getEmailFromProfile(data.data.sub, session_token);
          }
        });
    }
  }
  getEmailFromProfile(sub, token) {
    fetch(`${process.env.REACT_APP_API_MAIN}/users/v3/accounts/${sub}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          email: data.data.email,
        });
      });
  }
  componentDidMount() {
    try {
      var session_token = JSON.parse(
        sessionStorage.getItem(
          `oidc.user:${process.env.REACT_APP_ISSUER}:${process.env.REACT_APP_CLIENT_ID}`,
        ),
      ).access_token;
      this.setState({
        token: session_token,
      });
    } catch (error) {}
    if (this.props.name !== "") {
      this.checkTimeOut();
    }
    this.elem.focus();
    document.title = "TTRS Live Chat";
    if (this.props.priority) {
      this.setState({
        priority: this.props.priority,
      });
    }
    this.setQuestionClass();
    this.setPlatform();
  }
  setPlatform() {
    if (
      navigator.userAgent.toLowerCase().indexOf("android") > -1 ||
      navigator.userAgent.toLowerCase().indexOf("iphone") > -1
    ) {
      this.setState({
        platform: "webmobile",
        detailStlye: "black",
      });
      loginHeader = <div className="m-loginDetailHeader"></div>;
    } else {
      loginHeader = (
        <Row align="center">
          <div>
            <img
              alt="title-member"
              className="title-member-image"
              src={require("./../images/WWW-circle-01.png")}
            />
          </div>
          <div style={{ marginBottom: "10px" }}>
            <label className="title-member-header">ข้อมูลสมาชิก TTRS</label>
          </div>
        </Row>
      );
    }
  }
  setQuestionClass() {
    if (window.innerWidth <= 320) {
      this.setState({
        questionClass: 5,
      });
    } else if (window.innerWidth > 320 && window.innerWidth <= 550) {
      this.setState({
        questionClass: 4,
      });
    } else if (window.innerWidth > 550 && window.innerWidth < 850) {
      this.setState({
        questionClass: 3,
      });
    } else if (window.innerWidth >= 850) {
      this.setState({
        questionClass: 2,
      });
    }
  }

  render() {
    return (
      <AuthProvider {...this.state.oidcConfig}>
        <div>
          {loginHeader}
          <StyledRow>
            <Col s={1}></Col>
            <Col s={10}>
              <label className="normal-text">หมายเลขบัตรประจำตัวประชาชน</label>
              <input
                type="text"
                className="login-detail validate normal-text"
                style={{ color: this.state.detailStlye }}
                value={this.state.identification}
                // value={"ทดสอบ"}
                disabled={true}
              />
            </Col>
          </StyledRow>
          <StyledRow>
            <Col s={1}></Col>
            <Col s={5}>
              <label className="normal-text">ชื่อ</label>
              <input
                type="text"
                className="validate login-detail normal-text"
                style={{ color: this.state.detailStlye }}
                value={this.state.name}
                disabled={true}
              />
            </Col>
            <Col s={5}>
              <label className="normal-text">นามสกุล</label>
              <input
                type="text"
                className="validate login-detail normal-text"
                style={{ color: this.state.detailStlye }}
                value={this.state.lastname}
                disabled={true}
              />
            </Col>
          </StyledRow>
          <StyledRow>
            <Col s={1}></Col>
            <Col s={8}>
              <label className="normal-text">อีเมล์</label>
              <input
                type="text"
                className="validate login-detail normal-text"
                value={this.state.email}
                onChange={(event) => this.onEmailEdit(event)}
              />
            </Col>
            <Col s={2}>
              <Button
                style={{ fontSize: "20px", fontFamily: "Pridi", fontWeight: "bold" }}
                waves="light"
                large
                node="a"
                // style={{marginTop:"14px"}}
                className="blue col s12"
                onClick={(event) => this.editEmail(event)}
                disabled={this.state.emailValidate}
              >
                {this.state.emailTextBtn}
              </Button>
            </Col>
          </StyledRow>
          <StyledRow>
            <Col s={1}></Col>
            <Col s={10}>
              <label className="normal-text">เบอร์ติดต่อ</label>
              <input
                type="text"
                className="validate login-detail normal-text"
                value={this.state.mobileContact}
                ref={(elem) => {
                  this.elem = elem;
                }}
                onChange={(event) => this.onMobileContact(event)}
              />
            </Col>
          </StyledRow>
          <Row className="hide">
            <Col s={1}></Col>
            <Col s={10}>
              <RadioGroup onChange={this.onPriorityChange.bind(this)} horizontal>
                <ReversedRadioButton
                  className="col s5"
                  value="0"
                  pointColor="#2196F3"
                  rootColor="#828282"
                  checked={this.state.priority === "0" ? true : false}
                >
                  ปกติ
                </ReversedRadioButton>
                <ReversedRadioButton
                  className="col s5"
                  value="1"
                  pointColor="red"
                  rootColor="#828282"
                  checked={this.state.priority === "1" ? true : false}
                >
                  ฉุกเฉิน
                </ReversedRadioButton>
              </RadioGroup>
            </Col>
          </Row>
          <div className={this.state.priority === "0" ? "hide" : "show"}>
            <Row>
              <Col s={1}></Col>
              <div className="switch" onChange={(event) => this.onQuestionChange(event, 0)}>
                <Col s={this.state.questionClass}>
                  <label>
                    ไม่ใช่
                    <input type="checkbox" />
                    <span className="lever"></span>
                    ใช่
                  </label>
                </Col>
              </div>
              <label>รู้สึกตัวใช่ไหม</label>
            </Row>
            <Row>
              <Col s={1}></Col>
              <div className="switch" onChange={(event) => this.onQuestionChange(event, 1)}>
                <Col s={this.state.questionClass}>
                  <label>
                    ไม่ใช่
                    <input type="checkbox" />
                    <span className="lever"></span>
                    ใช่
                  </label>
                </Col>
              </div>
              <label>หายใจเหนื่อยใช่ไหม</label>
            </Row>
            <Row>
              <Col s={1}></Col>
              <div className="switch" onChange={(event) => this.onQuestionChange(event, 2)}>
                <Col s={this.state.questionClass}>
                  <label>
                    ไม่ใช่
                    <input type="checkbox" />
                    <span className="lever"></span>
                    ใช่
                  </label>
                </Col>
              </div>
              <label>ลุกแล้วยืนเวียนหัวใช่ไหม</label>
            </Row>
          </div>
          <StyledRow align="center">
            <Col s={1} />
            <Col s={10}>
              <Button
                style={{ fontSize: "20px", fontFamily: "Pridi", fontWeight: "bold" }}
                waves="light"
                large
                node="a"
                className={this.state.loginStatus === false ? "disabled col s12" : "green col s12"}
                onClick={(event) => this.accessLiveChat(event)}
              >
                {" "}
                เข้าใช้งาน
              </Button>
            </Col>
            <Col s={1} />
          </StyledRow>
          <Row></Row>
          <Row>
            <center>
              <label className="userdetail-log normal-text">{this.state.log}</label>
            </center>
          </Row>
        </div>
      </AuthProvider>
    );
  }
  onQuestionChange(event, question) {
    if (question === 1) {
      if (event.target.checked) {
        this.setState({
          question1: 1,
        });
      } else {
        this.setState({
          question1: 0,
        });
      }
    }
    if (question === 1.1) {
      if (event.target.checked) {
        this.setState({
          question1sub1: 1,
        });
      } else {
        this.setState({
          question1sub1: 0,
        });
      }
    }
    if (question === 2) {
      if (event.target.checked) {
        this.setState({
          question2: 1,
        });
      } else {
        this.setState({
          question2: 0,
        });
      }
    }
    if (question === 3) {
      if (event.target.checked) {
        this.setState({
          question3: 1,
        });
      } else {
        this.setState({
          question3: 0,
        });
      }
    }
  }
  onEmailEdit(event) {
    var validEmail = this.validateEmail(event.target.value);
    this.setState({
      email: event.target.value,
      emailValidate: validEmail,
    });
    if (validEmail) {
      this.setState({
        log: "รูปแบบอีเมลล์ไม่ถูกต้อง",
      });
    } else {
      this.setState({
        log: "",
      });
    }
  }
  validateEmail(email) {
    if (/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email)) {
      return false;
    }
    return true;
  }
  onMobileContact(event) {
    this.setState({
      mobileContact: event.target.value,
    });
  }
  onPriorityChange(value) {
    this.setState({
      priority: value,
    });
  }
  editEmail(event) {
    let formData = new FormData();
    formData.append("email", this.state.email);

    fetch(`${process.env.REACT_APP_API_MAIN}/users/v3/accounts/${this.state.token_id}/email`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${this.state.token}`,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          log: "อัพเดทอีเมล์เสร็จสิ้น",
        });
      });
  }
  checkTimeOut() {
    var senddata = { date: this.props.logindate };
    fetch(`${process.env.REACT_APP_URL_API}/api/channel/time`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(senddata),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.status === "OK") {
          this.setState({ timeout: false });
        } else {
          window.location.href = "https://ttrs.or.th";
        }
      });
  }

  accessLiveChat(event) {
    console.log("DAS");
    if (this.state.platform === "webmobile") {
      ReactDOM.render(
        <ClientAppMobile
          type="reactInternal"
          identification={this.state.identification}
          name={this.state.name}
          lastname={this.state.lastname}
          mobile={this.state.mobile}
          email={this.state.email}
          mobileContact={this.state.mobileContact}
          priority={this.state.priority}
          question1={this.state.question1}
          question1sub1={this.state.question1sub1}
          question2={this.state.question2}
          question3={this.state.question3}
        />,
        document.querySelector(".app"),
      );
    } else {
      ReactDOM.render(
        <ClientApp
          type="reactInternal"
          identification={this.state.identification}
          name={this.state.name}
          lastname={this.state.lastname}
          mobile={this.state.mobile}
          email={this.state.email}
          mobileContact={this.state.mobileContact}
          priority={this.state.priority}
          question1={this.state.question1}
          question1sub1={this.state.question1sub1}
          question2={this.state.question2}
          question3={this.state.question3}
        />,
        document.querySelector(".app"),
      );
    }
  }
}
export default LoginDetail;
