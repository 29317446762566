import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Button } from "react-materialize";
import AgentV3RecevingCall from "../agentv3_recevingcall";
import * as $ from "jquery";
var searchTableHeader, searchTableData;

class RecevingSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    if (this.props.searchData) {
      if (this.props.searchFrom === "ttrs") {
        searchTableHeader = (
          <thead className="#eeeeee grey lighten-3">
            <tr>
              <th>บัตรประชาชน</th>
              <th>คำนำหน้าชื่อ</th>
              <th>ชื่อ-นามสกุล</th>
              <th>โทร</th>
            </tr>
          </thead>
        );
        searchTableData = this.props.searchData.map((listItem, index) => {
          if (listItem) {
            return (
              <tr key={index}>
                <td>{listItem.identification}</td>
                <td>{listItem.prefixname}</td>
                <td>
                  {listItem.name} {listItem.lastname}
                </td>
                <td>
                  <Button onClick={(event) => this.openChat(listItem)}>Call</Button>
                </td>
              </tr>
            );
          } else {
            return <div></div>;
          }
        });
      } else if (this.props.searchFrom === "livechat") {
        searchTableHeader = (
          <thead className="#eeeeee grey lighten-3">
            <tr>
              <th>บัตรประชาชน</th>
              <th>ชื่อ-สกุล หูหนวก</th>
              <th>เบอร์หูดี</th>
              <th>ติดต่อล่าสุด</th>
              <th>โทร</th>
            </tr>
          </thead>
        );
        searchTableData = this.props.searchData.map((listItem, index) => {
          if (listItem) {
            return (
              <tr key={index} className={this.priorityColor(listItem.priority)}>
                <td>{listItem.identification}</td>
                <td>
                  {listItem.name} {listItem.lastname}
                </td>
                <td>{listItem.mobilecontact}</td>
                <td>{listItem.dtmcreated}</td>
                <td>
                  <Button
                    style={{ backgroundColor: "#348de5" }}
                    onClick={(event) => this.openChat(listItem)}
                  >
                    สร้างห้องสนทนา
                  </Button>
                </td>
              </tr>
            );
          } else {
            return <div></div>;
          }
        });
      }
    }
    return (
      <div>
        <table className="centered highlight bordered" style={{ border: "1px solid #eae3e3" }}>
          {searchTableHeader}
          <tbody>{searchTableData}</tbody>
        </table>
      </div>
    );
  }
  priorityName(priority) {
    if (priority === "1") {
      return "ฉุกเฉิน";
    } else {
      return "ปกติ";
    }
  }
  priorityColor(priority) {
    if (priority === "1") {
      return "receiving-emergency-list";
    }
    return "";
  }
  statusColor(status) {
    if (status === "chat") {
      return <font color="#423EFF">{status}</font>;
    } else if (status === "abandon") {
      return <font color="#F10E0F">{status}</font>;
    } else if (status === "serviceoffline") {
      return <font color="#423EFF">{status}</font>;
    } else if (status === "close") {
      return <font color="#228A12">{status}</font>;
    }
  }
  openChat(listItem) {
    fetch(`${process.env.REACT_APP_URL_API}/notification/getusernotification`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
      body: JSON.stringify({
        // identification : listItem.identification
        identification: listItem.identification,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        var token = "",
          mobile_device = "";
        token = data.token;
        mobile_device = data.mobile_device;

        // if (data.status === "OK") {
        ReactDOM.render(
          <AgentV3RecevingCall
            type="reactInternal"
            agentid={this.props.agentid}
            threadid={this.props.threadid}
            identification={listItem.identification}
            name={listItem.name}
            lastname={listItem.lastname}
            mobile={listItem.mobile}
            token={token}
            mobile_device={mobile_device}
            email={listItem.email}
            mobileContact={listItem.mobilecontact}
            priority={0}
          />,
          document.querySelector(".app"),
        );
        // }
      });
    // if (data.status === "OK") {
    //     ReactDOM.render(
    //         <AgentV3RecevingCall
    //             type            = "reactInternal"
    //             agentid         = {this.props.agentid}
    //             threadid        = {this.props.threadid}
    //             identification  = {listItem.identification}
    //             name            = {listItem.name}
    //             lastname        = {listItem.lastname}
    //             mobile          = {listItem.mobile}
    //             token           = {data.userdata.notificationid_livechat}
    //             mobile_device   = {data.userdata.mobile_device}
    //             email           = {listItem.email}
    //             mobileContact   = {listItem.mobilecontact}
    //             priority        = {0}
    //         />, document.querySelector('.app'));
    // }
  }
}
export default RecevingSearch;
