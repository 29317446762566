import React from "react";

const TypeMessageItem = ({ listItem, id, chatcomplete }) => {
  if (chatcomplete === "1") {
    return (
      <option id={listItem.threadid} value={listItem.type_id}>
        {listItem.name}
      </option>
    );
  } else {
    return (
      <option id={listItem.threadid} value={listItem.type_id}>
        {listItem.name}
      </option>
    );
  }
};
export default TypeMessageItem;
