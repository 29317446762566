import React, { Component } from "react";
import { NavItem } from "react-materialize";

class ClientHistoryItem extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const optionDetail = `วันที่ ${this.props.listItem.datecreated} ${this.props.listItem.timecreated}`;
    return (
      <NavItem
        id={this.props.listItem.threadid}
        className="nav-item-history"
        onClick={(event) => this.selectHistory(event)}
      >
        <center>{optionDetail}</center>
      </NavItem>
    );
  }

  selectHistory(event) {
    if (event.target.value !== "") {
      var threadid = this.props.threadid;
      window.open(`/history/${threadid}`, "popUpHistoryWindows", "height=700,width=700");
    }
  }
}

export default ClientHistoryItem;
