import React from "react";

export default function SystemClosed() {
  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        fontSize: "30px",
        flexDirection: "column",
      }}
    >
      <div>ยกเลิกบริการ ให้เปลี่ยนไปใช้บริการ TTRS Live Chat แทน</div>
    </div>
  );
}
