import React, { Component } from "react";
import styled from "styled-components";

const dateTime = require("node-datetime");
var StyledDivChat = styled.div`
  width: calc(100% - 350px);
  margin-top: 1px;
  margin-left: 20px;
`;
class ChatInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sender: this.props.user,
      realtime: false,
      text: "",
      dtmcreated: dateTime.create().format("Y-m-d H:M:S"),
    };
  }
  componentDidMount() {
    if (this.props.user === `agent`) {
      StyledDivChat = styled.div`
        width: calc(100% - 450px);
        margin-top: -14px;
        margin-left: 120px;
      `;
    }
    this.elem.focus();
    document.addEventListener("mousedown", this.handleClick, false);
  }
  UNSAFE_componentWillMount() {
    document.removeEventListener("mousedown", this.handleClick, false);
  }
  render() {
    return (
      <div
        className={
          this.props.threadStatus === "close" ? "close-thread-chatboxfooter" : "chatboxfooter"
        }
      >
        <StyledDivChat>
          <input
            type="text"
            style={{ borderBottom: 0, marginTop: 4 }}
            disabled="disabled"
            ref={(elem) => {
              this.elem = elem;
            }}
            value={this.state.text}
          />
        </StyledDivChat>
      </div>
    );
  }
}
export default ChatInput;
