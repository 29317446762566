import React, { Component } from "react";
import { Row, Col, Button } from "react-materialize";
// import { RadioGroup, ReversedRadioButton } from 'react-radio-buttons';
import ClientApp from "../client";
import ClientAppMobile from "../client_mobile";
import styled from "styled-components";
import ReactDOM from "react-dom";
const StyledRow = styled(Row)`
  margin-bottom: 0;
  line-height: 0;
`;
var loginHeader = "";
class LoginDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileContact: "",
      priority: "1",
      priorityCheckNormal: true,
      priorityCheckEmergency: false,
      detailStlye: "rgb(125, 124, 124)",
      identification: "",
      name: "",
      lastname: "",
      email: "",
      mobile: "",
      prefixname: "",
      log: "",
      emailTextBtn: "อัพเดท",
      emergency_type: "",
      emergency_type1_options1: false,
      emergency_type1_options2: false,
      emergency_type1_options3: false,
      emergency_type1_options4: false,
      questionClass: 3,
      platform: "",
      displayUserDetail: "hide",
      loginStatus: true,
    };
  }
  componentDidMount() {
    if (this.props.name !== "") {
      this.checkTimeOut();
    }
    document.title = "TTRS Live Chat";
    if (this.props.priority) {
      this.setState({
        priority: this.props.priority,
      });
    }
    this.setQuestionClass();
    this.setPlatform();
  }
  setPlatform() {
    if (
      navigator.userAgent.toLowerCase().indexOf("android") > -1 ||
      navigator.userAgent.toLowerCase().indexOf("iphone") > -1
    ) {
      this.setState({
        platform: "webmobile",
        detailStlye: "black",
      });
      loginHeader = <div className="m-loginDetailHeader"></div>;
    } else {
      loginHeader = (
        <Row align="center">
          <img
            alt="emergency-logo"
            className="title-member-image"
            src={require("./../images/Emer Web-circle-01.png")}
          />
          <div style={{ marginBottom: "10px" }}>
            <label className="title-member-header-emergency">สนทนาแบบฉุกเฉิน TTRS Live Chat</label>
          </div>
        </Row>
      );
    }
  }
  onEmailEdit(event) {
    var validEmail = this.validateEmail(event.target.value);
    this.setState({
      email: event.target.value,
      emailValidate: validEmail,
    });
    if (validEmail) {
      this.setState({
        log: "รูปแบบอีเมลล์ไม่ถูกต้อง",
      });
    } else {
      this.setState({
        log: "",
      });
    }
  }
  onNameEdit(event) {
    this.setState({ name: event.target.value });
  }
  onLastnameEdit(event) {
    this.setState({ lastname: event.target.value });
  }
  validateEmail(email) {
    if (/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email)) {
      return false;
    }
    return true;
  }
  editEmail(event) {
    var obj = {
      controller: "user",
      action: "editprofile",
      identification: this.state.identification,
      email: this.state.email,
    };
    var test = {
      params: JSON.stringify(obj),
      appkey: "3D569F9F115833DD",
    };
    var formData = new FormData();
    for (var name in test) {
      formData.append(name, test[name]);
    }
    fetch("https://api.ttrs.or.th/api.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        this.setState({
          log: "อัพเดทอีเมล์เสร็จสิ้น",
        });
      });
  }
  checkTimeOut() {
    if (this.props.identification === "9999999999999") {
      this.setState({
        timeout: false,
        displayUserDetail: "hide",
      });
      this.getUserDetail();
    } else {
      var senddata = {
        date: this.props.logindate,
      };
      fetch(`${process.env.REACT_APP_URL_API}/api/channel/time`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(senddata),
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          if (data.status === "OK") {
            this.setState({
              timeout: false,
              displayUserDetail: "",
            });
            this.getUserDetail();
          } else {
            window.location.href = "https://ttrs.or.th";
          }
        });
    }
  }
  setQuestionClass() {
    if (window.innerWidth <= 320) {
      this.setState({
        questionClass: 5,
      });
    } else if (window.innerWidth > 320 && window.innerWidth <= 550) {
      this.setState({
        questionClass: 4,
      });
    } else if (window.innerWidth > 550 && window.innerWidth < 850) {
      this.setState({
        questionClass: 3,
      });
    } else if (window.innerWidth >= 850) {
      this.setState({
        questionClass: 2,
      });
    }
  }
  render() {
    return (
      <div>
        {loginHeader}
        {/* <StyledRow>
                    <Col s={1}></Col>
                    <Col s={10}
                    hide>
                        <label >เบอร์ติดต่อ</label>
                        <input
                            type="text"
                            className="validate login-detail"
                            value={this.state.mobileContact}
                            ref={(elem) => {this.elem = elem}}
                            onChange={event=>this.onMobileContact(event)}
                        />
                    </Col>
                </StyledRow> */}
        <StyledRow className={this.state.displayUserDetail}>
          <Col s={1}></Col>
          <Col s={10}>
            <label className="emregency-text">หมายเลขบัตรประจำตัวประชาชน</label>
            <input
              type="text"
              className="login-detail validate "
              style={{ color: this.state.detailStlye }}
              value={this.state.identification}
              disabled="true"
            />
          </Col>
        </StyledRow>
        <StyledRow
        // className={this.state.displayUserDetail}
        >
          <Col s={1}></Col>
          <Col s={5}>
            <label className="emregency-text">ชื่อ</label>
            <input
              type="text"
              className="validate login-detail emregency-text"
              style={{ color: this.state.detailStlye }}
              value={this.state.name}
              onChange={(event) => this.onNameEdit(event)}
            />
          </Col>
          <Col s={5}>
            <label className="emregency-text">นามสกุล</label>
            <input
              type="text"
              className="validate login-detail emregency-text"
              style={{ color: this.state.detailStlye }}
              value={this.state.lastname}
              onChange={(event) => this.onLastnameEdit(event)}
            />
          </Col>
        </StyledRow>
        <StyledRow className={this.state.displayUserDetail}>
          <Col s={1}></Col>
          <Col s={10}>
            <label className="emregency-text">เบอร์มือถือ</label>
            <input
              type="text"
              className="validate login-detail emregency-text"
              style={{ color: this.state.detailStlye }}
              value={this.state.mobile}
              disabled="true"
            />
          </Col>
        </StyledRow>
        <StyledRow className={this.state.displayUserDetail}>
          <Col s={1}></Col>
          <Col s={7}>
            <label className="emregency-text">อีเมล์</label>
            <input
              type="text"
              className="validate login-detail emregency-text"
              value={this.state.email}
              onChange={(event) => this.onEmailEdit(event)}
            />
          </Col>
          <Col s={3}>
            <Button
              waves="light"
              large
              node="a"
              style={{ marginTop: "14px" }}
              className="blue col s12"
              onClick={(event) => this.editEmail(event)}
              disabled={this.state.emailValidate}
            >
              {this.state.emailTextBtn}
            </Button>
          </Col>
        </StyledRow>
        <StyledRow>
          <Col s={1}></Col>
          <Col s={10}>
            <label className="emregency-text">เบอร์ติดต่อ</label>
            <input
              type="text"
              className="validate login-detail emregency-text"
              value={this.state.mobileContact}
              ref={(elem) => {
                this.elem = elem;
              }}
              onChange={(event) => this.onMobileContact(event)}
            />
          </Col>
        </StyledRow>

        <div className={this.state.priority === "0" ? "hide" : "show emergency-choice"}>
          <Row>
            <div className="emergency-select" onChange={this.onEmergencyTypeChange.bind(this)}>
              <input type="radio" id="emer1" name="emer" value="doctor" />
              <label className="emergency-radio" for="emer1">
                ด่วนหมอ
              </label>
              <br />
              <input type="radio" id="emer2" name="emer" value="police" />
              <label className="emergency-radio" for="emer2">
                ด่วนตำรวจ
              </label>
              <br />
              <input type="radio" id="emer3" name="emer" value="fire" />
              <label className="emergency-radio" for="emer3">
                ด่วนไฟไหม้
              </label>
              <br />
            </div>
          </Row>
          <div
            className={this.state.emergency_type === "doctor" ? "" : "hide"}
            style={{ marginLeft: "-100px" }}
          >
            อาการป่วยเบื้องต้น
            <hr />
            <Row>
              <Col s={2}></Col>
              <div className="switch">
                <Col s={this.state.questionClass} style={{ width: "160px" }}>
                  <label className="emregency-text">
                    ไม่ใช่
                    <input
                      type="checkbox"
                      onChange={(event) => this.onQuestionChange(event, "option1")}
                    />
                    <span className="lever"></span>
                    ใช่
                  </label>
                </Col>
              </div>
              <label className="emregency-text">ผู้ป่วยหมดสติ เรียกไม่รู้สึกตัว ใช่หรือไม่</label>
            </Row>
            <Row className={this.state.emergency_type1_options1 === true ? "" : "hide"}>
              <Col s={2}></Col>
              <div className="switch">
                <Col s={this.state.questionClass} style={{ width: "160px" }}>
                  <label className="emregency-text">
                    ไม่ใช่
                    <input
                      type="checkbox"
                      onChange={(event) => this.onQuestionChange(event, "option2")}
                    />
                    <span className="lever"></span>
                    ใช่
                  </label>
                </Col>
              </div>
              <label className="emregency-text">บริเวณใกล้เคียงมีเครื่อง AED อยู่หรือไม่</label>
            </Row>
            <Row>
              <Col s={2}></Col>
              <div className="switch">
                <Col s={this.state.questionClass} style={{ width: "160px" }}>
                  <label className="emregency-text">
                    ไม่ใช่
                    <input
                      type="checkbox"
                      onChange={(event) => this.onQuestionChange(event, "option3")}
                    />
                    <span className="lever"></span>
                    ใช่
                  </label>
                </Col>
              </div>
              <label className="emregency-text">สำลักอาหาร ใช่หรือไม่</label>
            </Row>
            <Row>
              <Col s={2}></Col>
              <div className="switch">
                <Col s={this.state.questionClass} style={{ width: "160px" }}>
                  <label className="emregency-text">
                    ไม่ใช่
                    <input
                      type="checkbox"
                      onChange={(event) => this.onQuestionChange(event, "option4")}
                    />
                    <span className="lever"></span>
                    ใช่
                  </label>
                </Col>
              </div>
              <label className="emregency-text">มีอาการชักเกร็ง กระตุกหรือไม่</label>
            </Row>
          </div>
        </div>
        <br />
        <StyledRow align="center">
          <Col s={1} />
          <Col s={10}>
            <Button
              style={{ fontSize: "20px", fontFamily: "Pridi", fontWeight: "bold" }}
              waves="light"
              large
              node="a"
              className={this.state.loginStatus === false ? "disabled col s12" : "green col s12"}
              onClick={(event) => this.accessLiveChat(event)}
            >
              {" "}
              เข้าใช้งาน
            </Button>
          </Col>
          <Col s={1} />
        </StyledRow>
        <Row></Row>
        <Row>
          <center>
            <label className="userdetail-log">{this.state.log}</label>
          </center>
        </Row>
      </div>
    );
  }
  onQuestionChange(event, option) {
    switch (option) {
      case "option1":
        this.setState({ emergency_type1_options1: event.target.checked });
        break;
      case "option2":
        this.setState({ emergency_type1_options2: event.target.checked });
        break;
      case "option3":
        this.setState({ emergency_type1_options3: event.target.checked });
        break;
      case "option4":
        this.setState({ emergency_type1_options4: event.target.checked });
        break;
      default:
        break;
    }
    // console.log(this.state)
  }
  onMobileContact(event) {
    this.setState({
      mobileContact: event.target.value,
    });
  }
  onPriorityChange(value) {
    this.setState({
      priority: value,
    });
  }
  onEmergencyTypeChange(value) {
    this.setState({
      emergency_type: value.target.value,
    });
  }

  getUserDetail(event) {
    if (this.props.identification === "9999999999999") {
      this.setState({
        loginStatus: true,
        identification: Math.floor(Math.random() * 10000000000000),
        name: "",
        lastname: "",
        email: "-",
        mobile: "-",
        prefixname: "-",
      });
    } else {
      var obj = {
        controller: "user",
        action: "showdetailusers",
        identification: this.props.identification,
      };
      var test = {
        params: JSON.stringify(obj),
        appkey: "3D569F9F115833DD",
      };
      var formData = new FormData();
      for (var name in test) {
        formData.append(name, test[name]);
      }

      fetch("https://api.ttrs.or.th/api.php", {
        method: "POST",
        body: formData,
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          if (data.status === "FAIL") {
            this.setState({
              loginStatus: false,
            });
          } else if (data.status === "OK") {
            this.setState({
              loginStatus: true,
              identification: data.userdata.identification,
              name: data.userdata.name,
              lastname: data.userdata.lastname,
              email: data.userdata.email,
              mobile: data.userdata.mobile,
              prefixname: data.userdata.prefixname,
            });
          }
        });
    }
  }

  emergencyOptionResult() {
    var result = `${this.state.emergency_type1_options1 ? "1" : "0"},${
      this.state.emergency_type1_options2 ? "1" : "0"
    },${this.state.emergency_type1_options3 ? "1" : "0"},${
      this.state.emergency_type1_options4 ? "1" : "0"
    }`;
    // console.log(result)
    return result;
  }
  accessLiveChat(event) {
    if (this.state.platform === "webmobile") {
      ReactDOM.render(
        <ClientAppMobile
          type="reactInternal"
          identification={this.state.identification}
          name={this.state.name}
          lastname={this.state.lastname}
          mobile={this.state.mobile}
          email={this.state.email}
          mobileContact={this.state.mobileContact}
          priority={this.state.priority}
          emergency_type={this.state.emergency_type}
          emergency_type1_options={this.emergencyOptionResult()}
          question1={this.state.question1}
          question1sub1={this.state.question1sub1}
          question2={this.state.question2}
          question3={this.state.question3}
        />,
        document.querySelector(".app"),
      );
    } else {
      ReactDOM.render(
        <ClientApp
          type="reactInternal"
          identification={this.state.identification}
          name={this.state.name}
          lastname={this.state.lastname}
          mobile={this.state.mobile}
          email={this.state.email}
          mobileContact={this.state.mobileContact}
          priority={this.state.priority}
          emergency_type={this.state.emergency_type}
          emergency_type1_options={this.emergencyOptionResult()}
          question1={this.state.question1}
          question1sub1={this.state.question1sub1}
          question2={this.state.question2}
          question3={this.state.question3}
        />,
        document.querySelector(".app"),
      );
    }
  }
}
export default LoginDetail;

// priorityType : "",
// emergency_type1_options1 : false,
// emergency_type1_options2 : false,
// emergency_type1_options3 : false,
// emergency_type1_options4 : false,
