import React, { Component } from "react";

class EmergencyAlert extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    if (this.props.emergency_count > 0) {
      return (
        <a href={() => false}>
          <span data-badge-caption="Emergency" className="new badge red alert-emergency">
            {this.props.emergency_count}
          </span>
        </a>
      );
    } else {
      return <div></div>;
    }
  }
}

export default EmergencyAlert;
