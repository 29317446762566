import React, { Component } from "react";
import FontAdjust from "./font_adjust";

class NavLivechatGuest extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <nav className="chatboxheader">
        <div className="nav-wrapper nav-livechat">
          <ul id="nav-mobile" className="left hide-on-med-and-down nav-livechat-component">
            <li>
              <FontAdjust className="font-adjust-btn" type="increase" text="A+" />
            </li>
            <li>
              <FontAdjust className="font-adjust-btn" type="decrease" text="A-" />
            </li>
          </ul>
          <ul>
            <li>
              <a href={() => false} className="nav-reconnecting">
                {this.props.state.reconnected}
              </a>
            </li>
          </ul>
        </div>
      </nav>
    );
  }
}

export default NavLivechatGuest;
