import React from "react";
import ChatMessageItemMobile from "./chat_message_item_mobile";
import ChatMessageRealtimeMobile from "./chat_message_realtime_mobile";

const ChatContent = (props) => {
  if (!props.messageChat) {
    return <div></div>;
  }

  const chatItem = props.messageChat.map((messageChat, index) => {
    return (
      <ChatMessageItemMobile key={index} id={index} user={props.user} messageChat={messageChat} />
    );
  });
  return (
    <div className={props.user === "m-chatdata" ? "" : "m-chatdata"} id="chatdata">
      {chatItem}
      <ChatMessageRealtimeMobile
        user={props.user}
        messageChatRealtime={props.messageChatRealtime}
      />
      <div className="empty"></div>
    </div>
  );
};

export default ChatContent;
