var dateTime = require("node-datetime");

export function datetimefilter(dtmcreated) {
  if (dtmcreated) {
    dtmcreated = dtmcreated.split(" ")[1];
    dtmcreated = dtmcreated.split(":");
    return dtmcreated[0] + ":" + dtmcreated[1];
  } else {
    return dateTime.create().format("H:M");
  }
}
