import React, { Component } from "react";
import Modal from "react-modal";
const modalStyles = {
  content: {
    top: "35%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

class CloseRoom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      chatcomplete: "1",
      isTypeUpdate: false,
      closeBtnText: "จบการสนทนา",
      closeBtnClass: "change-agent-btn",
    };
  }
  UNSAFE_componentWillMount() {
    Modal.setAppElement("body");
  }
  render() {
    return (
      <div>
        <a
          href={() => false}
          className={this.state.closeBtnClass}
          onClick={this._handleModel.bind(this)}
        >
          เปลี่ยนเจ้าหน้าที่
        </a>
        <Modal style={modalStyles} isOpen={this.state.modalIsOpen} contentLabel="SelectTypeMessage">
          <h4>เปลี่ยนเจ้าหน้าที่</h4>
          <br></br>
          <div align="center">
            <button className="btn btn-primary" onClick={this._handleAccept.bind(this)}>
              ยืนยัน
            </button>
          </div>
          <br></br>
          <div align="center">
            <button
              className="btn btn-primary"
              style={{ backgroundColor: "#fd6262" }}
              onClick={this._handleClose.bind(this)}
            >
              ยกเลิก
            </button>
          </div>
        </Modal>
      </div>
    );
  }
  _handleModel() {
    this.setState({ modalIsOpen: true });
  }
  _handleClose() {
    this.setState({ modalIsOpen: false });
  }
  _handleAccept() {
    fetch(`${process.env.REACT_APP_URL_API}/agent/duplicateThread/`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        threadid: this.props.closedata.state.threadid,
      }),
    }).then((response) => {
      this.setState({ modalIsOpen: false });
      window.close();
      return response.json();
    });
  }
}

export default CloseRoom;
