import React, { Component } from "react";
import ChatContentMobile from "./components/chat_content_mobile";
import NavLivechatGuest from "./components/nav_livechat_guest";
import ChatInputGuest from "./components/chat_input_guest";
import Modal from "react-modal";

class AgentApp extends Component {
  constructor(props) {
    super(props);
    this.messageSend = this.messageSend.bind(this);
    this.state = {
      urlWS: process.env.REACT_APP_URL_WS,
      urlAPI: process.env.REACT_APP_URL_API,
      clientName: null,
      clientLastname: null,
      channel: null,
      threadid: props.threadid,
      agentid: props.agentid,
      socket: null,
      socketinfo: null,
      threadstatus: "chat",
      modalAlertChat: false,
      modalOpen: false,
      user: "agent",
      data: [],
      datarealtime: [],
      emergency_count: 0,
      clientAlway: 0,
      reconnected: "",
      buttonInputDisabled: false,
    };
  }
  componentDidMount() {
    this.getMessage();
    document.title = "TTRS Live Chat Agent";
  }
  UNSAFE_componentWillMount() {
    Modal.setAppElement("body");
  }
  connectSocket(channel, type = "") {
    const socket = new WebSocket(`${this.state.urlWS}/ws/${channel}`);
    socket.onopen = (evt) => {
      this.setState({
        reconnected: "",
      });
    };
    socket.onmessage = (event) => {
      if (type !== "info") {
        var parsedata = JSON.parse(event.data);
        if (typeof parsedata === "object") {
          this.messageChat(parsedata);
        }
      } else {
        this.setState({ emergency_count: JSON.parse(event.data).emergency });
      }
    };
    socket.onclose = () => {
      this.setState({
        reconnected: "Reconnecting",
      });
      setTimeout(() => {
        this.connectSocket(channel, type);
      }, 1000);
    };
    return socket;
  }
  getMessage() {
    fetch(
      `${this.state.urlAPI}/api/message/list/threadid/${this.state.threadid}?from=${this.state.user}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      },
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.threadstatus === "abandon") {
          data.threadstatus = "close";
        }
        this.setState({
          channel: data.channel,
          threadid: data.threadid,
          identification: data.identification,
          data: data.messagedata,
          threadstatus: data.threadstatus,
          clientName: data.name,
          clientLastname: data.lastname,
          socket: this.connectSocket(data.channel),
        });
      });
  }
  messageSend(data) {
    if (this.state.socket !== null) {
      this.state.socket.send(JSON.stringify(data));
    }
  }

  messageChat(data) {
    if (data.sender === "automessage" && data.text === "close;") {
      this.setState({
        threadstatus: "close",
      });
    } else if (data.realtime === false) {
      this.setState({
        data: [...this.state.data, data],
      });
      if (data.sender !== this.state.user) {
        this.setState({
          datarealtime: [],
        });
      }
    } else if (data.sender !== "automessage") {
      if (data.sender !== this.state.user) {
        this.setState({
          datarealtime: data,
        });
      }
    }
  }
  agentAccess(event) {
    this.agentStartChat(true);
    this.setState({
      modalAlertChat: false,
    });
  }

  render() {
    return (
      <div>
        <NavLivechatGuest state={this.state} />
        <div className="chatboxcontent">
          <ChatContentMobile
            user={this.state.user}
            messageChat={this.state.data}
            messageChatRealtime={this.state.datarealtime}
            threadStatus={this.state.threadstatus}
          />
        </div>
        <ChatInputGuest />
      </div>
    );
  }
}
export default AgentApp;
