import "babel-polyfill";
import "core-js/es6/map";
import "core-js/es6/set";
import React from "react";
import ReactDOM from "react-dom";
import AgentApp from "./agent";
import GuestApp from "./guest";
import HistoryApp from "./history";
import HistorySupApp from "./history_sup";
import MainApp from "./main";
import LoginApp from "./login";
import ReceivingApp from "./receiving";
import Test from "./test";
import AgentRecevingCallApp from "./agent_recevingcall";
import ReceivingV3SelectApp from "./receivingSelect";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import LoginDetailApp from "./components/login_detail";
import LoginDetailWebIdApp from "./components/login_detail_weblinkid";
import LoginDetailEmergencyApp from "./components/login_detail_emergency";
import WarningVersionApp from "./warn_version";
// import { AuthProvider } from "react-oidc-context";
import { AuthProvider } from "oidc-react";
// import RatingApp from './rating';
import { matchPath } from "react-router";
import SystemClosed from "./SystemClosed";

var aesjs = require("aes-js");

const updateAgentUsername = (threadid, agentusername) => {
  fetch(`${process.env.REACT_APP_URL_API}/v3/agent/updateagentusername`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
    body: JSON.stringify({
      threadid: threadid,
      preferred_username: agentusername,
    }),
  })
    .then((response) => {
      return response.json();
    })
    .then((data) => {});
};
const updateAccessToken = () => {
  console.log("updateAccessToken");
  var token_parse = JSON.parse(
    sessionStorage.getItem(
      `oidc.user:${process.env.REACT_APP_ISSUER_AGENT}:${process.env.REACT_APP_CLIENT_ID_AGENT}`,
    ),
  );
  if (token_parse !== null) {
    // console.log(token_parse.access_token)
    localStorage.setItem("access_token", token_parse.access_token);
    fetch(`${process.env.REACT_APP_URL_API}/v3/verifyagent`, {
      method: "POST",
      headers: { Authorization: `Bearer ${token_parse.access_token}` },
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        localStorage.setItem("family_name", response.data.family_name);
        localStorage.setItem("given_name", response.data.given_name);
        localStorage.setItem("preferred_username", response.data.preferred_username);

        const match = matchPath(window.location.pathname, {
          path: "/v3/receiving/:threadid",
          exact: true,
          strict: false,
        });

        try {
          updateAgentUsername(match.params.threadid, response.data.preferred_username);
        } catch (error) {}
      });
  }
};

const oidcConfig = {
  onSignIn: async () => {
    console.log("Onsign");
    updateAccessToken();
    window.location.hash = "";
    // const url = document.location.href;
    // window.history.pushState({}, "", url.split("?")[0]);
  },
  authority: process.env.REACT_APP_ISSUER_AGENT,
  clientId: process.env.REACT_APP_CLIENT_ID_AGENT,
  redirectUri: `${process.env.REACT_APP_DOMAIN}${window.location.pathname}`,
  automaticSilentRenew: true,
};

const LiveChatAgentRouter = () => (
  <Router>
    <Switch>
      <Route path="/agent/:agentid/thread/:threadid" component={Agent} />
      <Route path="/guest/:threadid" component={Guest} />
      <Route path="/history/:threadid" component={History} />
      <Route path="/history.sup/:threadid" component={HistorySup} />
      <Route path="/login" component={SystemClosedRouter} />
      {/*<Route path="/v3/normal" component={LoginDetailWebId} />*/}
      {/*<Route path="/v3/emergency" component={LoginEmergencyWithoutKey} />*/}
      {/*ปิดระบบบนเว็บชั่วคราว*/}
      <Route path="/v3/normal" component={SystemClosedRouter} />
      <Route path="/v3/emergency" component={SystemClosedRouter} />

      <Route path="/user/:identification" component={LoginDetailWithoutKey} />
      <Route path="/userdetail/:identification" component={LoginDetail} />
      <Route path="/emergency/user/:identification" component={LoginDetailEmergency} />
      <Route path="/emergency/userdetail/:identification" component={LoginDetailEmergency} />

      <Route path="/receiving/n/agent/:agentid/t/:threadid" component={AgentRecevingCall} />
      <Route path="/test" component={Test} />

      {/* <AuthProvider {...oidcConfig}> */}
      <Route path="/receiving/agent/:agentid/" component={Receiving} />
      <Route path="/v3/receiving/:threadid" component={ReceivingV3Select} />
      <Route path="/test" component={<Test />} />
      {/* </AuthProvider> */}
      {/* <AuthProvider {...oidcConfig}>
                <Route path="/clientweb.php*" component={WarningVersion} />
                <Route path='/test' component={Test} />
            </AuthProvider> */}
      <Route path="/clientweb.php*" component={WarningVersion} />
      <Route path="/" component={MainApp} />
    </Switch>
  </Router>
);

const SystemClosedRouter = () => <SystemClosed />;

// const LiveChatClientRouter = () => (
//     <Router >
//         <div>
//             <Switch>
//                 <Route path="/v3/emergency" component={LoginEmergencyWithoutKey}/>
//                 <Route path="/v3/normal"    component={LoginDetailWebId}/>
//                 <Route path="/emergency/user/:identification"  component={LoginDetailEmergency} />
//                 <Route path="/emergency/userdetail/:identification" component={LoginDetailEmergency} />
//                 <Route path="/emergency/" component={LoginEmergencyWithoutKey} />
//                 <Route path="/rating/:service_id/:threadid" component={Rating}/>
//                 <Route path="/m.guest/:threadid"  component={GuestMobile} />
//             </Switch>
//         </div>
//     </Router>
// );

// {/* <Route path="/agent/:agentid/thread/:threadid" component={Agent} />
// <Route path="/guest/:threadid"                 component={Guest} />
// <Route path="/m.guest/:threadid"               component={GuestMobile} />
// <Route path="/history/:threadid"               component={History} />
// <Route path="/history.sup/:threadid"           component={HistorySup} />
// <Route path="/login"                           component={Login} />
// <Route path="/v3/normal"                       component={LoginDetailWebId}/>
// <Route path="/v3/emergency"                    component={LoginEmergencyWithoutKey}/>
// <Route path="/user/:identification"            component={LoginDetailWithoutKey} />
// <Route path="/userdetail/:identification"      component={LoginDetail} />
// <Route path="/emergency/user/:identification"  component={LoginDetailEmergency} />
// <Route path="/emergency/userdetail/:identification" component={LoginDetailEmergency} />
// <Route path="/emergency/"                      component={LoginEmergencyWithoutKey} />
// <Route path="/receiving/agent/:agentid/"       component={Receiving} />
// <Route path="/clientweb.php*"                  component={WarningVersion} />
// <Route path="/rating/:service_id/:threadid"    component={Rating}/> */}

// const Rating = ({match}) => (
//     <RatingApp
//         threadid={match.params.threadid}
//         service_id={match.params.service_id}
//     />
// );
const Agent = ({ match }) => (
  <AgentApp threadid={match.params.threadid} agentid={match.params.agentid} />
);
// const GuestMobile = ({match}) => (
//     <GuestApp
//         threadid={match.params.threadid}
//         platform="webmobile"
//     />
// )

const Guest = ({ match }) => <GuestApp threadid={match.params.threadid} platform="web" />;
// const History = ({match}) => (
//     <ReactKeycloakProvider authClient={keycloak}>
//         <HistoryApp threadid={match.params.threadid} />
//     </ReactKeycloakProvider>
// )

const History = ({ match }) => <HistoryApp threadid={match.params.threadid} />;

const HistorySup = ({ match }) => <HistorySupApp threadid={match.params.threadid} />;
const Login = () => <LoginApp />;
const WarningVersion = () => <WarningVersionApp />;
const LoginDetailWithoutKey = ({ match }) => {
  return <LoginDetailApp platform="" identification={match.params.identification} priority="0" />;
};

const LoginDetailWebId = ({ match }) => {
  return <LoginDetailWebIdApp weblinkid={match.params.weblinkid} />;
};

const LoginDetail = ({ match }) => {
  var key = [61, 22, 93, 12, 67, 22, 31, 16, 27, 95, 19, 12, 55, 23, 10, 29];
  var encryptedBytes = aesjs.utils.hex.toBytes(match.params.identification);
  var aesCtr = new aesjs.ModeOfOperation.ctr(key, new aesjs.Counter(5));
  var decryptedBytes = aesCtr.decrypt(encryptedBytes);
  var decryptedText = aesjs.utils.utf8.fromBytes(decryptedBytes);
  decryptedText = decryptedText.split(",");
  var identification = decryptedText[0];
  var logindate = decryptedText[1];
  return (
    <LoginDetailApp
      platform=""
      identification={identification}
      logindate={logindate}
      priority="0"
    />
  );
};

const LoginDetailEmergency = ({ match }) => {
  var key = [61, 22, 93, 12, 67, 22, 31, 16, 27, 95, 19, 12, 55, 23, 10, 29];
  var encryptedBytes = aesjs.utils.hex.toBytes(match.params.identification);
  var aesCtr = new aesjs.ModeOfOperation.ctr(key, new aesjs.Counter(5));
  var decryptedBytes = aesCtr.decrypt(encryptedBytes);
  var decryptedText = aesjs.utils.utf8.fromBytes(decryptedBytes);
  decryptedText = decryptedText.split(",");
  var identification = decryptedText[0];
  var logindate = decryptedText[1];
  return (
    <LoginDetailEmergencyApp
      platform=""
      identification={identification}
      logindate={logindate}
      priority="1"
    />
  );
};
const LoginEmergencyWithoutKey = () => {
  return <LoginDetailEmergencyApp identification="9999999999999" platform="" priority="1" />;
};
const Receiving = ({ match }) => <ReceivingApp agentid={match.params.agentid} />;

const ReceivingV3Select = ({ match }) => <ReceivingV3SelectApp threadid={match.params.threadid} />;

const AgentRecevingCall = ({ match }) => (
  <AgentRecevingCallApp agentid={match.params.agentid} threadid={match.params.threadid} />
);

export default LiveChatAgentRouter;

ReactDOM.render(<LiveChatAgentRouter />, document.querySelector(".app"));
