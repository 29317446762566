import React, { useEffect, useState } from "react";
import { Row } from "react-materialize";
import styled from "styled-components";
import axios from "axios";

const StyledRow = styled(Row)`
  margin-bottom: -2px;
`;

const HistoryHeader = (props) => {
  const [rateData, setRateData] = useState({ rate: "-", comment: "-" });

  useEffect(() => {
    if (props.chatcomplete !== undefined) {
      const fetchRateDetailAPI = async () => {
        var service_id = 0;
        if (props.historydata.platform.includes("iOS")) {
          service_id = 4;
        } else if (props.historydata.platform.includes("android")) {
          service_id = 4;
        } else {
          service_id = 3;
        }
        const response = await axios(
          `${process.env.REACT_APP_URL_RATE_API}/list?icrm_id=${props.historydata.threadid}&service_id=${service_id}`,
        );
        if (response.data.status === "OK") {
          try {
            if (response.data.data[0] !== undefined) {
              setRateData(response.data.data[0]);
            }
          } catch (error) {
            console.log(error);
          }
        }
      };
      try {
        fetchRateDetailAPI();
      } catch (error) {
        console.log(error);
      }
    }
  }, [props]);

  const ViewRate = () => {
    if (props.level === "sup") {
      return (
        <div>
          <StyledRow>
            <div className="history-title">
              <div className="title">คะแนนประเมิน :</div>
            </div>
            <div className="history-detail">
              <div className="detail">
                <label className="red-text">{rateData.rate}</label>
              </div>
            </div>
          </StyledRow>
          <StyledRow>
            <div className="history-title">
              <div className="title">ข้อเสนอแนะ :</div>
            </div>
            <div className="history-detail">
              <div className="detail">
                <label className="red-text">{rateData.comment}</label>
              </div>
            </div>
          </StyledRow>
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  return (
    <div className="history-header">
      <StyledRow>
        <div className="history-title">
          <div className="title">ชื่อ-นามสกุล :</div>
        </div>
        <div className="history-detail">
          <div className="detail">
            {props.historydata.name} {props.historydata.lastname}
          </div>
        </div>
      </StyledRow>
      <StyledRow>
        <div className="history-title">
          <div className="title">เจ้าหน้าที่ :</div>
        </div>
        <div className="history-detail">
          <div className="detail">{props.historydata.agentname}</div>
        </div>
      </StyledRow>
      <StyledRow>
        <div className="history-title">
          <div className="title">ช่วงเวลาสนทนา :</div>
        </div>
        <div className="history-detail">
          <div className="detail">
            {props.historydata.dtmstartchat} ถึง {props.historydata.dtmclosed}
          </div>
        </div>
      </StyledRow>
      <StyledRow>
        <div className="history-title">
          <div className="title">เวลารอสาย :</div>
        </div>
        <div className="history-detail">
          <div className="detail">{props.historydata.waittime}</div>
        </div>
      </StyledRow>
      <StyledRow>
        <div className="history-title">
          <div className="title">เวลาในการสนทนา :</div>
        </div>
        <div className="history-detail">
          <div className="detail">{props.historydata.durationtime}</div>
        </div>
      </StyledRow>
      <StyledRow>
        <div className="history-title">
          <div className="title">เวลา Abandon :</div>
        </div>
        <div className="history-detail">
          <div className="detail">{props.historydata.waittime}</div>
        </div>
      </StyledRow>
      <StyledRow>
        <div className="history-title">
          <div className="title">สนทนาสำเร็จ :</div>
        </div>
        <div className="history-detail">
          <div className="detail">
            <label className="red-text">{props.chatcomplete}</label>
          </div>
        </div>
      </StyledRow>
      <StyledRow>
        <div className="history-title">
          <div className="title">ฉุกเฉินจริง :</div>
        </div>
        <div className="history-detail">
          <div className="detail">
            <label className="red-text">{props.historydata.is_emergency ? "ใช่" : "ไม่ใช่"}</label>
          </div>
        </div>
      </StyledRow>
      <StyledRow>
        <div className="history-title">
          <div className="title">ประเภทสนทนา :</div>
        </div>
        <div className="history-detail">
          <div className="detail">
            <label className="red-text">{props.typemessage}</label>
          </div>
        </div>
      </StyledRow>
      <ViewRate />
    </div>
  );
};

export default HistoryHeader;
