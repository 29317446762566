import React, { Component } from "react";
import CloseRoom from "./close_room";
import FontAdjust from "./font_adjust";
import ClientHistoryItem from "./client_history_item";
import ClientStatus from "./client_status_item";
import ChangeAgent from "./change_agent";
import EmergencyAlert from "./emergency_alert";
import EmergencyNiems from "./emergency_niems";
import { Dropdown } from "react-materialize";

class NavLivechat extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}
  render() {
    if (this.props.state.user === "agent") {
      if (!this.props.state.clienthistorylist) {
        return <div></div>;
      }
      const clientHistoryItem = this.props.state.clienthistorylist.map((listItem, index) => {
        if (listItem) {
          return (
            <ClientHistoryItem
              key={index}
              listItem={listItem}
              threadid={this.props.state.clienthistorylist[index].threadid}
            />
          );
        } else {
          return <div></div>;
        }
      });
      return (
        <nav className="chatboxheader">
          <div className="nav-wrapper nav-livechat">
            <ul id="nav-mobile" className="left hide-on-med-and-down nav-livechat-component">
              <li>
                <FontAdjust className="font-adjust-btn" type="increase" text="A+" />{" "}
              </li>
              <li>
                <FontAdjust className="font-adjust-btn" type="decrease" text="A-" />{" "}
              </li>
              <li>
                <ChangeAgent closedata={this.props} />
              </li>
              <li>
                <EmergencyNiems data={this.props} />
              </li>
            </ul>
            <ul id="nav-mobile" className="right hide-on-med-and-down nav-livechat-component">
              <li>
                <EmergencyAlert emergency_count={this.props.state.emergency_count} />
              </li>
              <li>
                <ClientStatus clientStatus={this.props.state.clientStatus} />
              </li>
              <li>
                <Dropdown
                  trigger={
                    <a href={() => false} className="client-history-btn">
                      ประวัติสนทนา​คุณ {this.props.state.clientName}
                    </a>
                  }
                >
                  {clientHistoryItem}
                </Dropdown>
              </li>
              <li>
                <CloseRoom closedata={this.props} />
              </li>
            </ul>
          </div>
        </nav>
      );
    } else {
      return (
        <nav className="chatboxheader">
          <div className="nav-wrapper nav-livechat">
            <ul id="nav-mobile" className="left hide-on-med-and-down nav-livechat-component">
              <li>
                <FontAdjust className="font-adjust-btn" type="increase" text="A+" />
              </li>
              <li>
                <FontAdjust className="font-adjust-btn" type="decrease" text="A-" />
              </li>
            </ul>
            <ul>
              <li>
                <a href={() => false} className="nav-reconnecting">
                  {this.props.state.reconnected}
                </a>
              </li>
            </ul>
            <ul id="nav-mobile" className="right hide-on-med-and-down nav-livechat-component">
              <li>
                <CloseRoom closedata={this.props} />
              </li>
            </ul>
          </div>
        </nav>
      );
    }
  }
}

export default NavLivechat;
