import React, { Component } from "react";

class ClientAlwayTime extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    if (this.props.clientStatus !== "") {
      return (
        <div>
          <a className="client-alway-btn">
            <span style={{ fontSize: "12px" }}>หูหนวกขาด</span>
            <span style={{ fontSize: "12px" }}>การเชื่อมต่อ</span>
          </a>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

export default ClientAlwayTime;
