import React, { Component } from "react";
import { Input, Row, Col, Button, ProgressBar } from "react-materialize";
import LoginDetail from "./components/login_detail";

class LoginApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      identification: "",
      name: "",
      lastname: "",
      username: "",
      password: "",
      email: "",
      mobile: "",
      mobilecontact: "",
      prefixname: "",
      loginStatus: "",
    };
  }
  componentDidMount() {
    document.title = "TTRS Live Chat";
  }
  render() {
    if (this.state.loginStatus === "login") {
      return (
        <div>
          <Row>
            <LoginDetail
              identification={this.state.identification}
              prefixname={this.state.prefixname}
              name={this.state.name}
              lastname={this.state.lastname}
              mobile={this.state.mobile}
              mobilecontact={this.state.mobilecontact}
              email={this.state.email}
            />
          </Row>
        </div>
      );
    } else {
      return (
        <div className="login-screen">
          <Row align="center">
            <h4>เข้าสู่ระบบ TTRS Live Chat</h4>
          </Row>
          <Row>
            <Col s={1}></Col>
            <Input
              type="text"
              label="Username (เลขที่บัตรประจำตัวประชาชน)"
              s={10}
              value={this.state.username}
              onChange={(event) => this.onUsernameChange(event)}
            />
          </Row>
          <Row>
            <Col s={1}></Col>
            <Input
              type="password"
              label="รห้สผ่าน"
              s={10}
              value={this.state.password}
              onChange={(event) => this.onPasswordChange(event)}
            />
          </Row>
          <Row className={this.state.loginStatus === "connecting" ? "" : "hide"}>
            <Col s={1}></Col>
            <Col s={9}>
              <ProgressBar />
            </Col>
          </Row>
          <Row>
            <Col s={1} />
            <Col s={10}>
              <div className={this.state.loginStatus === "" ? "" : "hide"}>
                <Button
                  waves="light"
                  large
                  node="a"
                  className="green col s12"
                  onClick={(event) => this.LiveChatLogin(event)}
                >
                  {" "}
                  ล็อคอิน{" "}
                </Button>
              </div>
            </Col>
            <Col s={1} />
          </Row>
        </div>
      );
    }
  }
  onUsernameChange(event) {
    this.setState({
      username: event.target.value,
    });
  }
  onPasswordChange(event) {
    this.setState({
      password: event.target.value,
    });
  }
  LiveChatLogin(event) {
    var sha1 = require("js-sha1");
    var obj = {
      controller: "user",
      action: "login",
      username: this.state.username,
      password: sha1(this.state.password),
    };
    var test = {
      params: JSON.stringify(obj),
      appkey: "3D569F9F115833DD",
    };
    var formData = new FormData();
    for (var name in test) {
      formData.append(name, test[name]);
    }
    fetch("https://api.ttrs.or.th/api.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        // console.log(response)
        return response.json();
      })
      .then((data) => {
        if (data.status === "FAIL") {
          this.setState({
            loginStatus: "",
          });
          alert("เลขประจำตัวประชาชน หรือ รหัสผ่านผิดพลาด");
        } else if (data.status === "OK") {
          this.setState({
            identification: data.userdata.identification,
            name: data.userdata.name,
            lastname: data.userdata.lastname,
            email: data.userdata.email,
            mobile: data.userdata.mobile,
            prefixname: data.userdata.prefixname,
            loginStatus: "login",
          });
        }
      });
  }
}
export default LoginApp;
