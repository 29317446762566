import React, { Component } from "react";
import CloseRoom from "./close_room";
import FontAdjust from "./font_adjust";

class NavLivechat extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <nav className="chatboxheader">
        <div className="nav-wrapper nav-livechat">
          <ul id="nav-mobile" className="left hide-on-med-and-down nav-livechat-component">
            <li
              style={{
                border: "1px solid white",
                marginTop: "-1px",
                marginLeft: "-1px",
              }}
            >
              <FontAdjust className="m-font-adjust-btn" type="increase" text="A+" />
            </li>
            <li
              style={{
                border: "1px solid white",
                marginTop: "-1px",
                marginLeft: "-1px",
              }}
            >
              <FontAdjust className="m-font-adjust-btn" type="decrease" text="A-" />
            </li>
          </ul>
          <ul>
            <li>
              <a href={() => false} className="nav-reconnecting">
                {this.props.state.reconnected}
              </a>
            </li>
          </ul>
          <ul id="nav-mobile" className="right hide-on-med-and-down nav-livechat-component">
            <li>
              <CloseRoom closedata={this.props} />
            </li>
          </ul>
        </div>
      </nav>
    );
  }
}

export default NavLivechat;
