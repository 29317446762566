import React, { Component } from "react";
import { timestamp } from "../function/timestamp";
var image, location;

class ChatMessageItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: "empty",
      chatWith: "empty",
    };
  }
  scrollToBottom = (behavior) => {
    this.messagesEnd.scrollIntoView({ behavior });
  };
  componentDidMount() {
    if (this.props.user === "agent") {
      this.setState({
        user: "agent",
        chatWith: "client",
      });
    } else {
      this.setState({
        user: "client",
        chatWith: "agent",
      });
    }
    this.scrollToBottom("instant");
    var parser, xmlDoc;
    if (this.props.messageChat.text !== null) {
      if (this.props.messageChat.text.includes("<image>")) {
        parser = new DOMParser();
        xmlDoc = parser.parseFromString(this.props.messageChat.text, "text/xml");
        var urlImage = xmlDoc.getElementsByTagName("image")[0].childNodes[0].nodeValue;
        image = (
          <a
            href={() => false}
            onClick={(event) => this.openWindowPopUp(event, urlImage)}
            className="m-livechat-image-thumbnail"
          >
            <img alt="chatpicture" className="m-chatimage" src={urlImage} />
          </a>
        );
        this.props.messageChat.text = image;
      } else if (this.props.messageChat.text.includes("<location>")) {
        parser = new DOMParser();
        xmlDoc = parser.parseFromString(this.props.messageChat.text, "text/xml");
        var latlng = xmlDoc.getElementsByTagName("location")[0].childNodes[0].nodeValue;
        var googleMap = "https://maps.google.com/maps?q=" + latlng + "&z=17&output=embed";
        location = (
          <div>
            <div className="gmap_canvas">
              <iframe
                title="map2"
                className="google_map"
                id="gmap_canvas"
                src={googleMap}
                frameBorder="0"
                scrolling="no"
                marginHeight="0"
                marginWidth="0"
              ></iframe>
            </div>
          </div>
        );
        this.props.messageChat.text = location;
      }
    }
  }
  openWindowPopUp(event, urlImage) {
    window.open(urlImage, "popUpWindow", "height=700,width=700");
  }
  openLocationGoogle(latlng) {
    var urlMap = "https://maps.google.com/maps?q=" + latlng + "&z=18";
    window.open(
      urlMap,
      "popUpWindow",
      "height=700,width=900,left=10,top=10,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no,status=yes",
    );
  }
  componentDidUpdate() {
    this.scrollToBottom("smooth");
  }
  messageBackgroundColor(type, location) {
    if (
      type === "welcomeclient" ||
      type === "welcomeclientemergency" ||
      type === "clientclose" ||
      type === "agentclose" ||
      type === "infoclient" ||
      type === "duplicateuser" ||
      type === "emergencyquestion" ||
      type === "infoagent" ||
      type === "recevingagentcreate"
    ) {
      return `msg-${location}-messagedata-info message`;
    }
    return `msg-${location}-messagedata message`;
  }
  render() {
    if (this.state.user !== this.props.messageChat.sender) {
      return (
        <div
          id={this.props.id}
          className="msg-row"
          ref={(el) => {
            this.messagesEnd = el;
          }}
        >
          <div className={this.messageBackgroundColor(this.props.messageChat.type, "left")}>
            {" "}
            {this.props.messageChat.text}{" "}
          </div>
          <span className="msg-time-right">{timestamp()} น.</span>
        </div>
      );
    } else {
      return (
        <div
          id={this.props.id}
          className="msg-row"
          ref={(el) => {
            this.messagesEnd = el;
          }}
        >
          <div className={this.messageBackgroundColor(this.props.messageChat.type, "right")}>
            {this.props.messageChat.text}
          </div>
          <span className="msg-time-left">{timestamp()} น.</span>
        </div>
      );
    }
  }
}

export default ChatMessageItem;
