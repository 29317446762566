import React, { Component } from "react";
const dateTime = require("node-datetime");
class ChatInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emojiSelect: "none",
      name: "",
      sender: this.props.user,
      inputCss: "m-livechat-input",
      realtime: false,
      text: "",
      emojiBtn: "😊",
      dtmcreated: dateTime.create().format("Y-m-d H:M:S"),
    };
  }
  componentDidMount() {
    if (this.props.user === `agent`) {
      this.setState({
        inputCss: "livechat-input-agent",
      });
    }
    if (this.props.name) {
      this.setState({
        name: this.props.name,
      });
    }
    this.elem.focus();
    document.addEventListener("mousedown", this.handleClick, false);
  }
  UNSAFE_componentWillMount() {
    document.removeEventListener("mousedown", this.handleClick, false);
  }

  render() {
    return (
      <div
        className={
          this.props.threadStatus === "close" ? "close-thread-chatboxfooter" : "chatboxfooter"
        }
      >
        <div className={this.state.inputCss}>
          <input
            type="text"
            style={{
              borderBottom: 0,
              marginTop: "3px",
              backgroundColor: "white",
              paddingLeft: "5px",
              paddingRight: "5px",
              borderRadius: "10px",
              border: "1px solid rgb(222, 222, 222)",
            }}
            className={
              this.props.threadStatus === "close"
                ? "close-input-field m-webkit"
                : "input-field-livechat m-webkit"
            }
            disabled={this.props.threadStatus === "close" ? "disabled" : ""}
            autoFocus
            placeholder={this.props.threadStatus === "close" ? "" : "พิมพ์ข้อความตรงนี้"}
            ref={(elem) => {
              this.elem = elem;
            }}
            onChange={(event) => this.onChangeInput(event)}
            onKeyUp={(event) => this.onInputKey(event)}
            value={this.state.text}
          />
        </div>
        <div className="m-livechat-send-div">
          <img
            className="image-send"
            alt={this.state.user}
            src={require(`../images/send.png`)}
            onClick={(event) => this.onClickSend(event)}
          />
        </div>
      </div>
    );
  }
  isImage(filename) {
    var ext = this.getExtension(filename);
    switch (ext.toLowerCase()) {
      case "jpg":
        return true;
      case "gif":
        return true;
      case "bmp":
        return true;
      case "png":
        return true;
      default:
        return false;
    }
  }
  getExtension(filename) {
    var parts = filename.split(".");
    return parts[parts.length - 1];
  }

  onClickShowEmoji(event) {
    if (this.state.emojiSelect === "") {
      this.setState({
        emojiSelect: "none",
      });
    } else {
      this.setState({
        emojiSelect: "",
      });
    }
  }
  addEmoji(event) {
    this.setState({
      text: this.state.text + "" + event.native,
    });
    this.messageSend(true, this.state.text);
  }
  selectAgentTemplate(event) {
    this.setState({
      text: event.target.text,
    });
    this.elem.focus();
  }
  messageSend(realtime, text) {
    var data = {
      sender: this.props.user,
      dtmcreated: dateTime.create().format("Y-m-d H:M:S"),
      realtime,
      name: this.state.name,
      text,
    };
    this.props.messageSend(data);
    // this.elem.focus();
  }
  onChangeInput(event) {
    this.setState({
      text: event.target.value,
    });
    this.messageSend(true, event.target.value);
  }
  onInputKey(event) {
    if (event.key === "Enter") {
      if (event.target.value !== "") {
        this.messageSend(false, event.target.value);
        event.target.value = "";
        this.setState({
          text: "",
        });
      }
    }
  }
  onClickSend(event) {
    this.setState({
      dtmcreated: dateTime.create().format("Y-m-d H:M:S"),
      realtime: false,
    });
    if (this.state.text !== "") {
      this.props.messageSend(this.state);
      this.setState({
        text: "",
      });
    }
    this.elem.focus();
  }
  sendLocation(event) {
    if (navigator.geolocation) {
      // device can return its location
      navigator.geolocation.getCurrentPosition(
        function (position) {
          var location = `<location>${position.coords.latitude},${position.coords.longitude}</location>`;
          console.log(location);
          this.messageSend(false, location);
          this.elem.focus();
        }.bind(this),
      );
    } else {
      alert("ไม่พบข้อมูลพิกัด");
    }
  }
}
export default ChatInput;
