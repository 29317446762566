import React, { Component } from "react";
class FontAdjust extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fontLimitMax: 30,
      fontLimitMin: 5,
      fontSizeDefault: 14,
    };
  }
  componentDidMount() {
    document.getElementById("chatdata").style.fontSize =
      localStorage.getItem("livechatFont") + "px";
  }
  render() {
    return (
      <div>
        <a
          href={() => false}
          className={this.props.className}
          onClick={(event) => this.changeFontSize(event)}
        >
          <center>{this.props.text}</center>
        </a>
      </div>
    );
  }
  changeFontSize(event) {
    var el = document.getElementById("chatdata");
    var style = window.getComputedStyle(el, null).getPropertyValue("font-size");
    var fontSize = parseFloat(style);

    if (this.props.type === "increase") {
      if (fontSize < this.state.fontLimitMax) {
        fontSize++;
      }
    } else {
      if (fontSize > this.state.fontLimitMin) {
        fontSize--;
      }
    }
    localStorage.setItem("livechatFont", fontSize);
    document.getElementById("chatdata").style.fontSize = fontSize + "px";
  }
}

export default FontAdjust;
